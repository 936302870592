var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("header", { staticClass: "page-header-wrapper" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "page-header__main" }, [
          _c("h1", { staticClass: "page-header__title" }, [
            _vm._v(_vm._s(_vm.$tt("contents.title")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("nav", { staticClass: "page-nav-wrapper" }, [
      _c("ul", { staticClass: "page-nav" }, [
        _c("li", { staticClass: "page-nav__item" }, [
          _c(
            "a",
            { staticClass: "page-nav__link active", attrs: { href: "#" } },
            [_vm._v(_vm._s(_vm.$tt("contents.tab_1_title")))]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-nav__item" }, [
          _c("a", { staticClass: "page-nav__link", attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$tt("contents.tab_2_title")))
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-nav__item" }, [
          _c("a", { staticClass: "page-nav__link", attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$tt("contents.tab_3_title")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page-layout page-empty" }, [
      _c("svg", { staticClass: "svg-icon" }, [
        _c("use", { attrs: { "xlink:href": "#icon-page" } })
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "page-empty__header" }, [
        _vm._v(_vm._s(_vm.$tt("contents.coming_soon_message")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }