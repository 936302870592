var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "page-frame__aside", class: { active: _vm.menuIsOpen } },
    [
      _c("div", { staticClass: "mobile-nav", on: { click: _vm.toggleMenu } }, [
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("nav", { staticClass: "nav-sidebar" }, [
        _c("div", { staticClass: "sidebar-avatar" }, [_c("account-menu")], 1),
        _vm._v(" "),
        _c("ul", { staticClass: "nav-sidebar__group" }, [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "home" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-general" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.home")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "stores" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-store" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.stores")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "affiliates" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-promotion" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.affiliates")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "apps" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-app" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.apps")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "payments" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-app" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.payments")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "themes" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-theme" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.themes")))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-sidebar__link",
                  attrs: { to: { name: "settings" } }
                },
                [
                  _c("svg", { staticClass: "sidebar-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-setting" } })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-label" }, [
                    _vm._v(_vm._s(_vm.$tt("sidebar.settings")))
                  ])
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "burger" }, [
      _c("span"),
      _c("span"),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }