var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "froala_wrapper_" + _vm._uid } },
    [
      _c("froala", {
        ref: "froala_" + _vm._uid,
        attrs: {
          id: "froala_" + _vm._uid,
          tag: "textarea",
          config: _vm.config
        },
        model: {
          value: _vm.body_html,
          callback: function($$v) {
            _vm.body_html = $$v
          },
          expression: "body_html"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }