<template>

	<layout blank class="site-auth">

		<div class="page-layout">

			<div class="card">

				<div class="card__section">
					<div class="site-auth__logo">
						<a href="/"><img src="/images/partners/partners-logo.svg" alt="logo"></a>
					</div>
					<hr>

					<h1 class="site-auth__title">{{ $tt('auth.reset_password.title') }}</h1>

					<form :class="{ 'form-loading': form_loading }" @submit.prevent="reset">

						<div class="input-wrapper input-wrapper--small">

							<input
							type="password"
							name="password"
							:placeholder="$tt('auth.reset_password.placeholder.password')"
							:class="{ error:errors.has('password'), 'input-control': true }"
							v-model.trim="auth.password"
							v-validate="'required|min:6'"
							data-vv-validate-on="none"
							:data-vv-as="$tt('auth.reset_password.placeholder.password')"
							>

							<span v-if="errors.has('password')" class="error-block">{{ errors.first('password') }}</span>

						</div>



						<div class="input-wrapper input-wrapper--small">

							<input
							type="password"
							name="password_confirmation"
							:placeholder="$tt('auth.reset_password.placeholder.password_confirmation')"
							:class="{ error:errors.has('password_confirmation'), 'input-control': true }"
							v-model.trim="auth.password_confirmation"
							v-validate="{ required: true, min: 6, confirmed: auth.password }"
							data-vv-delay="1000"
							:data-vv-as="$tt('auth.reset_password.placeholder.password_confirmation')"
							>

							<span v-if="errors.has('password_confirmation')" class="error-block">{{ errors.first('password_confirmation') }}</span>

						</div>

						<div class="input-wrapper input-wrapper--small">
							<button type="submit" class="btn btn-primary">{{ $tt('auth.reset_password.submit_button') }}</button>
						</div>

						<div class="input-wrapper input-wrapper--small">
							<router-link :to="{ name: 'login' }">{{ $tt('auth.reset_password.back_to_login_link') }}</router-link>
						</div>
					</form>


				</div>

			</div>

		</div>

	</layout>

</template>

<script>
export default {
  data() {
    return {
      auth: {
        token: this.$route.query.reset_token,
        password: null,
        password_confirmation: null
      },

      form_loading: false
    };
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },
    reset() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;
          axios
            .post("_api/auth/reset_password", this.auth)
            .then(response => {
              this.toast(
                "success",
                this.$tt("auth.reset_password.success_message")
              );

              this.$router.push("/login");
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    },
    verify() {
      axios
        .post("/_api/auth/reset_password/verify", {
          token: this.$route.query.reset_token
        })
        .catch(error => {
          console.log(error)
          this.toast("error", error.response.data.error);
          this.$router.push("/login");
        })
        .then(() => {});
    }
  },

  created() {
    this.verify();
  }
};
</script>
