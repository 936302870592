<template>
  <layout>
    <header class="page-header-wrapper">
      <div class="page-header">
        <div class="page-header__main">
          <h1 class="page-header__title">{{ $tt('stores.title') }}</h1>
        </div>

        <div class="page-header__action">
          <a
            @click="show_create_store_dialog = true"
            class="btn btn-primary"
          >{{ $tt('stores.create_store_button') }}</a>
        </div>
      </div>
    </header>

    <section v-if="!loading">
      <nav class="page-nav-wrapper">
        <ul class="page-nav">
          <li class="page-nav__item">
            <a
              class="page-nav__link active"
            >{{ $tt('stores.tab_1_title') }} ({{ stores == null ? '0' : stores.length }})</a>
          </li>
        </ul>
      </nav>

      <div v-if="stores != null" class="page-layout">
        <div class="layout">
          <section class="layout__section">
            <table class="table-list">
              <thead>
                <tr>
                  <th>{{ $tt('stores.list_name') }}</th>
                  <th class="text-center">{{ $tt('stores.created_at') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="stores.length > 0">
                <tr v-for="store in stores" :key="store.name">
                  <td>
                    <a :href="storefront_url(store.name)" target="_blank">{{ store.title }}</a>
                  </td>
                  <td class="text-center">{{ created_at(store.created_at) }}</td>
                  <td class="text-right">
                    <a class="btn btn-link" @click="login_store(store.id)">
                      <svg class="svg-icon">
                        <use xlink:href="#icon-store"></use>
                      </svg>
                      <span>{{ $tt('stores.login_button') }}</span>
                    </a>

                    <delete-button
                      :text="$tt('stores.delete_store')"
                      :icon="'trash'"
                      :form_loading="form_loading"
                      :api="{
												url: '/_api/stores/'+store.id,
												callback: () => { list() }
											}"
                      :dialog="{
												title: $tt('stores.delete_dialog_title', {value1: store.name }),
												description: $tt('stores.delete_dialog_description'),
												button: {
													cancel: $tt('common.cancel_button'),
													confirm: $tt('stores.delete_button'),
												},
												message: {
													success: $tt('stores.delete_dialog_success_msg'),
													error: $tt('stores.delete_dialog_error_msg'),
												},
											}"
                    ></delete-button>
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="3">{{ $tt('stores.empty_row_message') }}</td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>

      <div v-else class="page-layout page-empty">
        <svg class="svg-icon">
          <use xlink:href="#icon-store"></use>
        </svg>
        <h2 class="page-empty__header">{{ $tt('stores.empty_store_message') }}</h2>
      </div>
    </section>

    <dialog-create-store :open_dialog.sync="show_create_store_dialog" @callback="list"></dialog-create-store>
  </layout>
</template>

<script>
export default {
  data() {
    return {
      stores: null,
      loading: true,
      form_loading: false,

      show_create_store_dialog: false
    };
  },

  components: {
    "dialog-create-store": require("./components/dialogCreateStore.vue").default
  },

  methods: {
    storefront_url(name) {
      return "https://" + name + process.env.MIX_SF_DOMAIN;
    },

    created_at(date) {
      return window._moment(date).format("DD-MM-YYYY");
    },

    list() {
      axios
        .get("_api/stores")
        .then(response => {
          if (response.data.stores) {
            this.stores = response.data.stores;
          } else {
            this.stores = null;
          }
        })
        .catch(error => {
          this.error_message = error.response.data.error;
        })
        .then(() => {
          this.loading = false;
        });
    },

    login_store(id) {
      axios
        .post("_api/stores/" + id + "/login")
        .then(response => {
          window.location.href = response.data.redirect_url;
        })
        .catch(error => {
          this.error_message = error.response.data.error;
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.list();
  }
};
</script>
