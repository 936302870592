import VueRouter from "vue-router";

let routes = [
  { path: "/", name: "home", component: require("./views/home.vue").default },
  {
    path: "/login",
    name: "login",
    component: require("./views/auth/login.vue").default
  },
  {
    path: "/signup",
    name: "signup",
    component: require("./views/auth/signup.vue").default
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: require("./views/auth/forgot_password.vue").default
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: require("./views/auth/reset_password.vue").default
  },
  {
    path: "/affiliates",
    name: "affiliates",
    component: require("./views/affiliates/affiliates.vue").default
  },

  {
    path: "/contents",
    name: "contents",
    component: require("./views/contents/contents.vue").default
  },

  {
    path: "/apps",
    name: "apps",
    component: require("./views/apps/apps.vue").default
  },

  {
    path: "/apps/new",
    name: "createApp",
    component: require("./views/apps/editApp.vue").default
  },

  {
    path: "/apps/:app_id",
    name: "editApp",
    component: require("./views/apps/editApp.vue").default
  },

  {
    path: "/themes",
    name: "themes",
    component: require("./views/themes/themes.vue").default
  },

  {
    path: "/settings",
    name: "settings",
    component: require("./views/settings/settings.vue").default
  },

  {
    path: "/stores",
    name: "stores",
    component: require("./views/stores/stores.vue").default
  },

  {
    path: "/uilab",
    name: "uilab",
    component: require("./views/ui/uilab.vue").default
  },
  
  {
    path: "/payments",
    name: "payments",
    component: require("./views/payments/payments.vue").default
  },
  {
    path: "/payments/new",
    name: "createPayment",
    component: require("./views/payments/editPayment.vue").default
  },
  {
    path: "/payments/:payment_id",
    name: "editPayment",
    component: require("./views/payments/editPayment.vue").default
  },

  { path: "/*", redirect: { name: "home" } }
];

export default new VueRouter({
  routes,
  linkExactActiveClass: "active",
  mode: "history"
});
