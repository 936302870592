var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "form",
      {
        class: { "form-loading": _vm.form_loading },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.update($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "topbar-action" },
          [
            _c(
              "router-link",
              { staticClass: "btn", attrs: { to: { name: "apps" } } },
              [_vm._v(_vm._s(_vm.$tt("common.cancel_button")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(_vm._s(_vm.$tt("common.save_button")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("header", { staticClass: "page-header-wrapper" }, [
          _c("div", { staticClass: "page-header" }, [
            _c("div", { staticClass: "page-header__main" }, [
              _c("h1", { staticClass: "page-header__title" }, [
                _vm._v(
                  _vm._s(_vm.$tt("apps.title")) +
                    " / " +
                    _vm._s(
                      this.app.id == null
                        ? _vm.$tt("apps.create_app")
                        : this.app.name
                    )
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page-header__action app-header__action" },
                [
                  _c("div", { staticClass: "action-link" }, [
                    _vm.app.id != null
                      ? _c(
                          "a",
                          {
                            attrs: { href: _vm.app_url, target: "blank" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.$tracking.custom_event("view", "app")
                              }
                            }
                          },
                          [
                            _c("svg", { staticClass: "svg-icon" }, [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-new-tab" }
                              })
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$tt("apps.view_app")))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.app.id != null
                    ? _c(
                        "div",
                        { staticClass: "action-link" },
                        [
                          _c("delete-button", {
                            attrs: {
                              text: _vm.$tt("apps.delete_app"),
                              icon: "trash",
                              form_loading: _vm.form_loading,
                              api: {
                                url: "/_api/apps/" + _vm.app.id,
                                redirect: { path: "/apps", query: null }
                              },
                              dialog: {
                                title: _vm.$tt("apps.delete_dialog_title", {
                                  value1: _vm.app.name
                                }),
                                description: _vm.$tt(
                                  "apps.delete_dialog_description"
                                ),
                                button: {
                                  cancel: _vm.$tt("common.cancel_button"),
                                  confirm: _vm.$tt("apps.delete_button")
                                },
                                message: {
                                  success: _vm.$tt(
                                    "apps.delete_dialog_success_msg"
                                  ),
                                  error: _vm.$tt("apps.delete_dialog_error_msg")
                                }
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.id != null && _vm.app.is_approved == 1
                    ? _c(
                        "div",
                        { staticClass: "action-link" },
                        [
                          _c("publish-button", {
                            attrs: {
                              text: _vm.app.is_published
                                ? _vm.$tt("apps.unpublish_app")
                                : _vm.$tt("apps.publish_app"),
                              icon: _vm.app.is_published ? "cancel" : "confirm",
                              form_loading: _vm.form_loading,
                              api: {
                                url: _vm.app.is_published
                                  ? "/_api/apps/" + _vm.app.id + "/unpublish"
                                  : "/_api/apps/" + _vm.app.id + "/publish",
                                redirect: {
                                  path: "/apps/" + _vm.app.id,
                                  query: null
                                }
                              },
                              dialog: {
                                title: _vm.app.is_published
                                  ? _vm.$tt("apps.unpublish_dialog_title", {
                                      value1: _vm.app.name
                                    })
                                  : _vm.$tt("apps.publish_dialog_title", {
                                      value1: _vm.app.name
                                    }),
                                description: _vm.app.is_published
                                  ? _vm.$tt("apps.unpublish_dialog_description")
                                  : _vm.$tt("apps.publish_dialog_description"),
                                button: {
                                  cancel: _vm.$tt("common.cancel_button"),
                                  confirm: _vm.app.is_published
                                    ? _vm.$tt("apps.unpublish_button")
                                    : _vm.$tt("apps.publish_button")
                                },
                                message: {
                                  success: _vm.app.is_published
                                    ? _vm.$tt(
                                        "apps.unpublish_dialog_success_msg"
                                      )
                                    : _vm.$tt(
                                        "apps.publish_dialog_success_msg"
                                      ),
                                  error: _vm.app.is_published
                                    ? _vm.$tt(
                                        "apps.unpublish_dialog_success_msg"
                                      )
                                    : _vm.$tt("apps.publish_dialog_error_msg")
                                }
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.id != null &&
                  (_vm.app.is_approved == 2 || _vm.app.is_approved == 0)
                    ? _c(
                        "div",
                        { staticClass: "action-link" },
                        [
                          _c("review-button", {
                            attrs: {
                              text: _vm.$tt("apps.review_app"),
                              form_loading: _vm.form_loading,
                              api: {
                                url: "/_api/apps/" + _vm.app.id + "/submit",
                                redirect: { path: "/apps", query: null }
                              },
                              dialog: {
                                title: _vm.$tt("apps.review_dialog_title", {
                                  value1: _vm.app.name
                                }),
                                description: _vm.$tt(
                                  "apps.review_dialog_description"
                                ),
                                button: {
                                  cancel: _vm.$tt("common.cancel_button"),
                                  confirm: _vm.$tt("apps.review_button")
                                },
                                message: {
                                  success: _vm.$tt(
                                    "apps.review_dialog_success_msg"
                                  ),
                                  error: _vm.$tt("apps.review_dialog_error_msg")
                                }
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-layout home-layout" }, [
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$tt("apps.app.title")))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$tt("apps.app.description")))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      { staticClass: "input-label", attrs: { for: "name" } },
                      [_vm._v(_vm._s(_vm.$tt("apps.app.name")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.name,
                          expression: "app.name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("name"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "name",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt("apps.app.name")
                      },
                      domProps: { value: _vm.app.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.app, "name", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("name")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("name")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "short_description" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.app.short_description")))]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.short_description,
                          expression: "app.short_description",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:120",
                          expression: "'max:120'"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("short_description"),
                        "input-control": true
                      },
                      attrs: {
                        name: "short_description",
                        "data-vv-delay": "550",
                        "data-vv-as": _vm.$tt("apps.app.short_description")
                      },
                      domProps: { value: _vm.app.short_description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "short_description",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.errors.has("short_description")
                      ? _c("span", { staticClass: "help-block text-right" }, [
                          _vm._v(_vm._s(_vm.short_description_remaining))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errors.has("short_description")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("short_description")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper" },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "" } },
                        [_vm._v(_vm._s(_vm.$tt("apps.app.app_description")))]
                      ),
                      _vm._v(" "),
                      _c("editor", {
                        attrs: {
                          placeholder: _vm.$tt(
                            "apps.app.description_placeholder"
                          )
                        },
                        model: {
                          value: _vm.app.description,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "description", $$v)
                          },
                          expression: "app.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "support_url" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.url.support_url")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.support_url,
                          expression: "app.support_url",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { url: { require_protocol: true } },
                          expression: "{ url: { require_protocol: true } }"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("support_url"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "support_url",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt("apps.url.support_url"),
                        placeholder: _vm.$tt("apps.url.support_url_placeholder")
                      },
                      domProps: { value: _vm.app.support_url },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "support_url",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("support_url")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("support_url")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        _vm._s(_vm.$tt("apps.url.support_url_description"))
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$tt("apps.url.title")))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$tt("apps.url.description")))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "application_url" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.url.application_url")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.application_url,
                          expression: "app.application_url",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            required: true,
                            url: { require_protocol: true }
                          },
                          expression:
                            "{ required: true, url: { require_protocol: true } }"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("application_url"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "application_url",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt("apps.url.application_url"),
                        placeholder: _vm.$tt(
                          "apps.url.application_url_placeholder"
                        )
                      },
                      domProps: { value: _vm.app.application_url },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "application_url",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("application_url")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("application_url")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.$tt("apps.url.application_url_description")
                          ) +
                          "\n\t\t\t\t\t\t\t\t"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "fulfillment_url" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.url.fulfillment_url")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.fulfillment_url,
                          expression: "app.fulfillment_url",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { url: { require_protocol: true } },
                          expression: "{ url: { require_protocol: true } }"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("fulfillment_url"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "fulfillment_url",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt("apps.url.fulfillment_url")
                      },
                      domProps: { value: _vm.app.fulfillment_url },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "fulfillment_url",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("fulfillment_url")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("fulfillment_url")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "redirection_url" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.url.redirection_url")))]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.redirection_url,
                          expression: "app.redirection_url",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "input-control",
                      attrs: {
                        name: "redirection_url",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt("apps.url.redirection_url"),
                        placeholder: _vm.$tt(
                          "apps.url.redirection_url_placeholder"
                        )
                      },
                      domProps: { value: _vm.app.redirection_url },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "redirection_url",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("redirection_url")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("redirection_url")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        _vm._s(_vm.$tt("apps.url.redirection_url_description"))
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$tt("apps.proxy.title")))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$tt("apps.proxy.description")))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "proxy_url" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.proxy.proxy_url")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.proxy_url,
                          expression: "app.proxy_url",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            url: { require_protocol: true },
                            required:
                              (_vm.app.proxy_prefix != null &&
                                _vm.app.proxy_prefix.length > 0) ||
                              (_vm.app.proxy_sub_path != null &&
                                _vm.app.proxy_sub_path.length > 0)
                          },
                          expression:
                            "{ url: { require_protocol: true }, required: ( (app.proxy_prefix != null && app.proxy_prefix.length > 0) || (app.proxy_sub_path != null && app.proxy_sub_path.length > 0) ) }"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("proxy_url"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "proxy_url",
                        placeholder: _vm.$tt(
                          "apps.proxy.proxy_url_placeholder"
                        ),
                        "data-vv-as": _vm.$tt("apps.proxy.proxy_url")
                      },
                      domProps: { value: _vm.app.proxy_url },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "proxy_url",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("proxy_url")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("proxy_url")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      { staticClass: "input-label", attrs: { for: "" } },
                      [_vm._v(_vm._s(_vm.$tt("apps.proxy.proxy_prefix")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.app.proxy_prefix,
                            expression: "app.proxy_prefix",
                            modifiers: { trim: true }
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required:
                                (_vm.app.proxy_url != null &&
                                  _vm.app.proxy_url.length > 0) ||
                                (_vm.app.proxy_sub_path != null &&
                                  _vm.app.proxy_sub_path.length > 0)
                            },
                            expression:
                              "{ required: ( (app.proxy_url != null && app.proxy_url.length > 0) || (app.proxy_sub_path != null && app.proxy_sub_path.length > 0) ) }"
                          }
                        ],
                        staticClass: "input-control",
                        class: { error: _vm.errors.has("proxy_prefix") },
                        attrs: {
                          name: "proxy_prefix",
                          clearable: "",
                          placeholder: "Select",
                          "data-vv-as": _vm.$tt("apps.proxy.proxy_prefix")
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.app,
                              "proxy_prefix",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: {
                              disabled: "",
                              selected: "",
                              label: "Select"
                            },
                            domProps: { value: null }
                          },
                          [_vm._v("Select")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "a", label: "a" } }, [
                          _vm._v("a")
                        ]),
                        _vm._v(" "),
                        _c(
                          "option",
                          { attrs: { value: "apps", label: "apps" } },
                          [_vm._v("apps")]
                        ),
                        _vm._v(" "),
                        _c(
                          "option",
                          { attrs: { value: "community", label: "community" } },
                          [_vm._v("community")]
                        ),
                        _vm._v(" "),
                        _c(
                          "option",
                          { attrs: { value: "tools", label: "tools" } },
                          [_vm._v("tools")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("proxy_prefix")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("proxy_prefix")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "proxy_sub_path" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("apps.proxy.sub_path")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.app.proxy_sub_path,
                          expression: "app.proxy_sub_path",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            required:
                              (_vm.app.proxy_url != null &&
                                _vm.app.proxy_url.length > 0) ||
                              (_vm.app.proxy_prefix != null &&
                                _vm.app.proxy_prefix.length > 0)
                          },
                          expression:
                            "{ required: ( (app.proxy_url != null && app.proxy_url.length > 0) || (app.proxy_prefix != null && app.proxy_prefix.length > 0) ) }"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("proxy_sub_path"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "proxy_sub_path",
                        placeholder: _vm.$tt("apps.proxy.sub_path_placeholder"),
                        "data-vv-as": _vm.$tt("apps.proxy.sub_path")
                      },
                      domProps: { value: _vm.app.proxy_sub_path },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.app,
                            "proxy_sub_path",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("proxy_sub_path")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("proxy_sub_path")))
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$tt("apps.icon.title")))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$tt("apps.icon.description")))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$tt("apps.icon.icon")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-group form-group--spaced upload-wrapper"
                      },
                      [
                        _c("div", { staticClass: "app-icon__wrapper" }, [
                          _c("img", {
                            attrs: { src: _vm.app.img_icon_url, alt: "icon" }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "upload-cta" },
                          [
                            _c("upload-button", {
                              attrs: {
                                apiUrl: "/_api/images/upload?type=app_icon",
                                mbLimit: "10",
                                accept: "image/png, image/gif, image/jpeg",
                                form_loading: _vm.form_loading,
                                image_url: _vm.app.img_icon_url
                              },
                              on: {
                                "update:form_loading": function($event) {
                                  _vm.form_loading = $event
                                },
                                "update:image_url": function($event) {
                                  return _vm.$set(
                                    _vm.app,
                                    "img_icon_url",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.$tt("apps.icon.icon_description")) +
                          "\n\t\t\t\t\t\t\t\t"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$tt("apps.icon.banner")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-group form-group--spaced upload-wrapper"
                      },
                      [
                        _c("div", { staticClass: "app-banner__wrapper" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.app.img_banner_url,
                              alt: "banner"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "upload-cta" },
                          [
                            _c("upload-button", {
                              attrs: {
                                apiUrl: "/_api/images/upload?type=app_banner",
                                mbLimit: "10",
                                accept: "image/png, image/gif, image/jpeg",
                                form_loading: _vm.form_loading,
                                image_url: _vm.app.img_banner_url
                              },
                              on: {
                                "update:form_loading": function($event) {
                                  _vm.form_loading = $event
                                },
                                "update:image_url": function($event) {
                                  return _vm.$set(
                                    _vm.app,
                                    "img_banner_url",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.$tt("apps.icon.banner_description")) +
                          "\n\t\t\t\t\t\t\t\t"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$tt("apps.icon.embed")))
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.is_embedded,
                            expression: "app.is_embedded"
                          }
                        ],
                        attrs: { type: "radio", id: "disable" },
                        domProps: {
                          value: false,
                          checked: _vm._q(_vm.app.is_embedded, false)
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "is_embedded", false)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-checkmark" }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "disable" } }, [
                        _vm._v(_vm._s(_vm.$tt("apps.icon.disable_button")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.app.is_embedded,
                            expression: "app.is_embedded"
                          }
                        ],
                        attrs: { type: "radio", id: "enable" },
                        domProps: {
                          value: true,
                          checked: _vm._q(_vm.app.is_embedded, true)
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.app, "is_embedded", true)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-checkmark" }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "enable" } }, [
                        _vm._v(_vm._s(_vm.$tt("apps.icon.enable_button")))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.app.app_id != null
            ? _c("div", { staticClass: "layout layout--2" }, [
                _c("section", { staticClass: "layout__section" }, [
                  _c("div", { staticClass: "card card--transparent" }, [
                    _c("div", { staticClass: "card__section" }, [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$tt("apps.credential.title")))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$tt("apps.credential.description")))
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("section", { staticClass: "layout__section" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card__section" }, [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "client_id" }
                          },
                          [_vm._v(_vm._s(_vm.$tt("apps.credential.client_id")))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.app.app_id,
                              expression: "app.app_id"
                            }
                          ],
                          ref: "copy_id",
                          staticClass: "input-control",
                          attrs: {
                            readonly: "",
                            type: "text",
                            name: "client_id"
                          },
                          domProps: { value: _vm.app.app_id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.app, "app_id", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "client_secret" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$tt("apps.credential.client_secret"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "input-control-group show-secret-field"
                          },
                          [
                            _vm.client_secret_field === "checkbox"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.app.app_secret,
                                      expression: "app.app_secret"
                                    }
                                  ],
                                  staticClass: "input-control",
                                  attrs: {
                                    readonly: "",
                                    name: "client_secret",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.app.app_secret)
                                      ? _vm._i(_vm.app.app_secret, null) > -1
                                      : _vm.app.app_secret
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.app.app_secret,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.app,
                                              "app_secret",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.app,
                                              "app_secret",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.app, "app_secret", $$c)
                                      }
                                    }
                                  }
                                })
                              : _vm.client_secret_field === "radio"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.app.app_secret,
                                      expression: "app.app_secret"
                                    }
                                  ],
                                  staticClass: "input-control",
                                  attrs: {
                                    readonly: "",
                                    name: "client_secret",
                                    type: "radio"
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.app.app_secret, null)
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.app,
                                        "app_secret",
                                        null
                                      )
                                    }
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.app.app_secret,
                                      expression: "app.app_secret"
                                    }
                                  ],
                                  staticClass: "input-control",
                                  attrs: {
                                    readonly: "",
                                    name: "client_secret",
                                    type: _vm.client_secret_field
                                  },
                                  domProps: { value: _vm.app.app_secret },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.app,
                                        "app_secret",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    _vm.hide_secret = !_vm.hide_secret
                                  }
                                }
                              },
                              [
                                _c("svg", { staticClass: "svg-icon" }, [
                                  _c(
                                    "use",
                                    _vm._b(
                                      {},
                                      "use",
                                      {
                                        "xlink:href": _vm.hide_secret
                                          ? "#icon-eye"
                                          : "#icon-eye-slash"
                                      },
                                      false
                                    )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }