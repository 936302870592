var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c(
      "form",
      {
        class: { "form-loading": _vm.form_loading },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.update($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "topbar-action" },
          [
            _c(
              "router-link",
              { staticClass: "btn", attrs: { to: { name: "payments" } } },
              [_vm._v(_vm._s(_vm.$tt("common.cancel_button")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(_vm._s(_vm.$tt("common.save_button")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("header", { staticClass: "page-header-wrapper" }, [
          _c("div", { staticClass: "page-header" }, [
            _c("div", { staticClass: "page-header__main" }, [
              _c("h1", { staticClass: "page-header__title" }, [
                _vm._v(
                  _vm._s(_vm.$tt("payments.title")) +
                    " / " +
                    _vm._s(
                      this.payment.id == null
                        ? _vm.$tt("payments.create_payment")
                        : this.payment.name
                    )
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page-header__action app-header__action" },
                [
                  _c("div", { staticClass: "action-link" }, [
                    _vm.payment.id != null
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.payment_gateway_url,
                              target: "blank"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.$tracking.custom_event(
                                  "view",
                                  "payment"
                                )
                              }
                            }
                          },
                          [
                            _c("svg", { staticClass: "svg-icon" }, [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-new-tab" }
                              })
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$tt("payments.view_payment")))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.payment.id != null
                    ? _c(
                        "div",
                        { staticClass: "action-link" },
                        [
                          _c("delete-button", {
                            attrs: {
                              text: _vm.$tt("payments.delete_payment"),
                              icon: "trash",
                              form_loading: _vm.form_loading,
                              api: {
                                url: "/_api/payments/" + _vm.payment.id,
                                redirect: { path: "/payments", query: null }
                              },
                              dialog: {
                                title: _vm.$tt("payments.delete_dialog_title", {
                                  value1: _vm.payment.name
                                }),
                                description: _vm.$tt(
                                  "payments.delete_dialog_description"
                                ),
                                button: {
                                  cancel: _vm.$tt("common.cancel_button"),
                                  confirm: _vm.$tt("payments.delete_button")
                                },
                                message: {
                                  success: _vm.$tt(
                                    "payments.delete_dialog_success_msg"
                                  ),
                                  error: _vm.$tt(
                                    "payments.delete_dialog_error_msg"
                                  )
                                }
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.payment.id != null && _vm.payment.is_approved == 1
                    ? _c(
                        "div",
                        { staticClass: "action-link" },
                        [
                          _c("publish-button", {
                            attrs: {
                              text: _vm.payment.is_published
                                ? _vm.$tt("payments.unpublish_payment")
                                : _vm.$tt("payments.publish_payment"),
                              icon: _vm.payment.is_published
                                ? "cancel"
                                : "confirm",
                              form_loading: _vm.form_loading,
                              api: {
                                url: _vm.payment.is_published
                                  ? "/_api/payments/" +
                                    _vm.payment.id +
                                    "/unpublish"
                                  : "/_api/payments/" +
                                    _vm.payment.id +
                                    "/publish",
                                redirect: {
                                  path: "/payments/" + _vm.payment.id,
                                  query: null
                                }
                              },
                              dialog: {
                                title: _vm.payment.is_published
                                  ? _vm.$tt("payments.unpublish_dialog_title", {
                                      value1: _vm.payment.name
                                    })
                                  : _vm.$tt("payments.publish_dialog_title", {
                                      value1: _vm.payment.name
                                    }),
                                description: _vm.payment.is_published
                                  ? _vm.$tt(
                                      "payments.unpublish_dialog_description"
                                    )
                                  : _vm.$tt(
                                      "payments.publish_dialog_description"
                                    ),
                                button: {
                                  cancel: _vm.$tt("common.cancel_button"),
                                  confirm: _vm.payment.is_published
                                    ? _vm.$tt("payments.unpublish_button")
                                    : _vm.$tt("payments.publish_button")
                                },
                                message: {
                                  success: _vm.payment.is_published
                                    ? _vm.$tt(
                                        "payments.unpublish_dialog_success_msg"
                                      )
                                    : _vm.$tt(
                                        "payments.publish_dialog_success_msg"
                                      ),
                                  error: _vm.payment.is_published
                                    ? _vm.$tt(
                                        "payments.unpublish_dialog_success_msg"
                                      )
                                    : _vm.$tt(
                                        "payments.publish_dialog_error_msg"
                                      )
                                }
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.payment.id != null &&
                  (_vm.payment.is_approved == 2 || _vm.payment.is_approved == 0)
                    ? _c(
                        "div",
                        { staticClass: "action-link" },
                        [
                          _c("review-button", {
                            attrs: {
                              text: _vm.$tt("payments.review_payment"),
                              form_loading: _vm.form_loading,
                              api: {
                                url:
                                  "/_api/payments/" +
                                  _vm.payment.id +
                                  "/submit",
                                redirect: { path: "/payments", query: null }
                              },
                              dialog: {
                                title: _vm.$tt("payments.review_dialog_title", {
                                  value1: _vm.payment.name
                                }),
                                description: _vm.$tt(
                                  "payments.review_dialog_description"
                                ),
                                button: {
                                  cancel: _vm.$tt("common.cancel_button"),
                                  confirm: _vm.$tt("payments.review_button")
                                },
                                message: {
                                  success: _vm.$tt(
                                    "payments.review_dialog_success_msg"
                                  ),
                                  error: _vm.$tt(
                                    "payments.review_dialog_error_msg"
                                  )
                                }
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-layout home-layout" }, [
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$tt("payments.payment.title")))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$tt("payments.payment.description")))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      { staticClass: "input-label", attrs: { for: "name" } },
                      [_vm._v(_vm._s(_vm.$tt("payments.payment.name")))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.payment.name,
                          expression: "payment.name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("name"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "name",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt("payments.payment.name")
                      },
                      domProps: { value: _vm.payment.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.payment,
                            "name",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("name")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("name")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "description" }
                      },
                      [_vm._v(_vm._s(_vm.$tt("payments.payment.description")))]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.payment.description,
                          expression: "payment.description",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:120",
                          expression: "'max:120'"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("description"),
                        "input-control": true
                      },
                      attrs: {
                        name: "description",
                        "data-vv-delay": "550",
                        "data-vv-as": _vm.$tt("payments.payment.description")
                      },
                      domProps: { value: _vm.payment.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.payment,
                            "description",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.errors.has("description")
                      ? _c("span", { staticClass: "help-block text-right" }, [
                          _vm._v(_vm._s(_vm.description_remaining))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errors.has("description")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("description")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-wrapper" },
                    [
                      _c(
                        "label",
                        { staticClass: "input-label", attrs: { for: "" } },
                        [_vm._v(_vm._s(_vm.$tt("payments.payment.guideline")))]
                      ),
                      _vm._v(" "),
                      _c("editor", {
                        attrs: {
                          placeholder: _vm.$tt(
                            "payments.payment.guideline_placeholder"
                          )
                        },
                        model: {
                          value: _vm.payment.guideline,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "guideline", $$v)
                          },
                          expression: "payment.guideline"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-label",
                        attrs: { for: "hosted_payment_url" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$tt("payments.payment.hosted_payment_url"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.payment.hosted_payment_url,
                          expression: "payment.hosted_payment_url",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { url: { require_protocol: true } },
                          expression: "{ url: { require_protocol: true } }"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("hosted_payment_url"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "hosted_payment_url",
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt(
                          "payments.payment.hosted_payment_url"
                        ),
                        placeholder: _vm.$tt(
                          "payments.payment.hosted_payment_url_placeholder"
                        )
                      },
                      domProps: { value: _vm.payment.hosted_payment_url },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.payment,
                            "hosted_payment_url",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("hosted_payment_url")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("hosted_payment_url")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tt(
                            "payments.payment.hosted_payment_url_description"
                          )
                        )
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$tt("payments.credential.title")))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$tt("payments.credential.description")))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "form-group form-group--2" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "label",
                        {
                          staticClass: "input-label",
                          attrs: { for: "account1" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$tt("payments.credential.account1"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.payment.account1,
                            expression: "payment.account1",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "input-control",
                        attrs: { type: "text", name: "account1" },
                        domProps: { value: _vm.payment.account1 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payment,
                              "account1",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "label",
                        {
                          staticClass: "input-label",
                          attrs: { for: "secret1" }
                        },
                        [_vm._v(_vm._s(_vm.$tt("payments.credential.secret1")))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.payment.secret1,
                            expression: "payment.secret1",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "input-control",
                        attrs: { type: "text", name: "secret1" },
                        domProps: { value: _vm.payment.secret1 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payment,
                              "secret1",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$tt("payments.supported.title")))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$tt("payments.supported.description")))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$tt("payments.supported.country")))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.countries,
                          expression: "payment.countries"
                        }
                      ],
                      attrs: { type: "checkbox", id: "my", value: "MY" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.countries)
                          ? _vm._i(_vm.payment.countries, "MY") > -1
                          : _vm.payment.countries
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.countries,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "MY",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "countries", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "my" } }, [_vm._v("Malaysia")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.countries,
                          expression: "payment.countries"
                        }
                      ],
                      attrs: { type: "checkbox", id: "sg", value: "SG" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.countries)
                          ? _vm._i(_vm.payment.countries, "SG") > -1
                          : _vm.payment.countries
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.countries,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "SG",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "countries", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "sg" } }, [
                      _vm._v("Singapore")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.countries,
                          expression: "payment.countries"
                        }
                      ],
                      attrs: { type: "checkbox", id: "tw", value: "TW" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.countries)
                          ? _vm._i(_vm.payment.countries, "TW") > -1
                          : _vm.payment.countries
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.countries,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "TW",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "countries", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "tw" } }, [_vm._v("Taiwan")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.countries,
                          expression: "payment.countries"
                        }
                      ],
                      attrs: { type: "checkbox", id: "th", value: "TH" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.countries)
                          ? _vm._i(_vm.payment.countries, "TH") > -1
                          : _vm.payment.countries
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.countries,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "TH",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "countries", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "th" } }, [_vm._v("Thailand")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.countries,
                          expression: "payment.countries"
                        }
                      ],
                      attrs: { type: "checkbox", id: "us", value: "US" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.countries)
                          ? _vm._i(_vm.payment.countries, "US") > -1
                          : _vm.payment.countries
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.countries,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "US",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "countries",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "countries", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "us" } }, [
                      _vm._v("United State")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$tt("payments.supported.currency")))
                    ]),
                    _c("br"),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.currencies,
                          expression: "payment.currencies"
                        }
                      ],
                      attrs: { type: "checkbox", id: "myr", value: "MYR" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.currencies)
                          ? _vm._i(_vm.payment.currencies, "MYR") > -1
                          : _vm.payment.currencies
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.currencies,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "MYR",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "currencies", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "myr" } }, [_vm._v("MYR")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.currencies,
                          expression: "payment.currencies"
                        }
                      ],
                      attrs: { type: "checkbox", id: "sgd", value: "SGD" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.currencies)
                          ? _vm._i(_vm.payment.currencies, "SGD") > -1
                          : _vm.payment.currencies
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.currencies,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "SGD",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "currencies", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "sgd" } }, [_vm._v("SGD")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.currencies,
                          expression: "payment.currencies"
                        }
                      ],
                      attrs: { type: "checkbox", id: "twd", value: "TWD" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.currencies)
                          ? _vm._i(_vm.payment.currencies, "TWD") > -1
                          : _vm.payment.currencies
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.currencies,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "TWD",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "currencies", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "twd" } }, [_vm._v("TWD")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.currencies,
                          expression: "payment.currencies"
                        }
                      ],
                      attrs: { type: "checkbox", id: "thb", value: "THB" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.currencies)
                          ? _vm._i(_vm.payment.currencies, "THB") > -1
                          : _vm.payment.currencies
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.currencies,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "THB",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "currencies", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "thb" } }, [_vm._v("THB")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payment.currencies,
                          expression: "payment.currencies"
                        }
                      ],
                      attrs: { type: "checkbox", id: "usd", value: "USD" },
                      domProps: {
                        checked: Array.isArray(_vm.payment.currencies)
                          ? _vm._i(_vm.payment.currencies, "USD") > -1
                          : _vm.payment.currencies
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.payment.currencies,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "USD",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.payment,
                                  "currencies",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.payment, "currencies", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "usd" } }, [_vm._v("USD")])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "layout layout--2" }, [
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card card--transparent" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$tt("payments.icon.title")))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$tt("payments.icon.description")))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "layout__section" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__section" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$tt("payments.icon.icon")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-group form-group--spaced upload-wrapper"
                      },
                      [
                        _c("div", { staticClass: "app-icon__wrapper" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.payment.img_icon_url,
                              alt: "icon"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "upload-cta" },
                          [
                            _c("upload-button", {
                              attrs: {
                                apiUrl: "/_api/images/upload?type=app_icon",
                                mbLimit: "10",
                                accept: "image/png, image/gif, image/jpeg",
                                form_loading: _vm.form_loading,
                                image_url: _vm.payment.img_icon_url
                              },
                              on: {
                                "update:form_loading": function($event) {
                                  _vm.form_loading = $event
                                },
                                "update:image_url": function($event) {
                                  return _vm.$set(
                                    _vm.payment,
                                    "img_icon_url",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "help-block" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.$tt("payments.icon.icon_description")) +
                          "\n\t\t\t\t\t\t\t\t"
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }