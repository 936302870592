<template>

	<layout>
		
		<header class="page-header-wrapper">
			<div class="page-header">
				<div class="page-header__main">
					<h1 class="page-header__title">Welcome to UI Lab</h1>


					<div class="page-header__action">
						<div class="action-link">
							<a href="#" class="">
								<svg class="svg-icon"><use xlink:href="#icon-plus"></use></svg>
								<span>Add</span>
							</a>
						</div>
						<div class="action-link">
							<a href="#" class="">
								<svg class="svg-icon"><use xlink:href="#icon-edit"></use></svg>
								<span>Edit</span>
							</a>
						</div>
						<div class="action-link">
							<a href="#" class="">
								<svg class="svg-icon"><use xlink:href="#icon-trash"></use></svg>
								<span>Delete</span>
							</a>
						</div>
					</div>

				</div>
				<div class="page-header__action">
					-----
				</div>
			</div>
		</header>
		

		<div class="page-layout">
			<div class="layout">
				<section class="layout__section">
					<div class="card card--transparent">
						<header class="card__header">
							<h2>Table</h2>
						</header>
						<div class="card__section">
							<table class="table-list">
								<thead>
									<tr>
										<th>App name</th>
										<th class="text-center">Current installation</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="index in 6">
										<td><a href="">My app</a></td>
										<td class="text-center">1</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</section>
			</div>


			<div class="layout">
				<section class="layout__section">
					<div class="card">
						<header class="card__header">
							<h2>Froala Editor</h2>
						</header>
						<div class="card__section">
							<editor></editor>
						</div>
					</div>
				</section>
			</div>

			<div class="layout">
				<section class="layout__section">
					<div class="card">
						<header class="card__header">
							<h2>Welcome to EasyStore Icon SVG</h2>
						</header>
						<div class="card__section">
							<div class="input-wrapper">
								<label class="input-label">Use case</label>
								<textarea readonly class="input-control"> <svg class="svg-icon"><use xlink:href="#icon-name" ></use></svg> </textarea>
							</div>

							<div class="icon-grid">
								<div class="icon-cell ui-lab-svg-container" v-for="icon in icons">
									<svg class="ui-lab-svg-icon"><use v-bind="{'xlink:href':'#icon-'+icon}" ></use></svg>
									<span>icon-{{icon}}</span>
								</div>
							</div>

						</div>
					</div>
				</section>
			</div>


		</div>

	</layout>

</template>
<style lang="scss">
.icon-grid{
	display: flex;
	flex-flow: row wrap;
	.icon-cell{
		width: 25%;
	}
}
.ui-lab-svg-container{
	display: flex;
	align-items: center;
	span{
		margin-left: 10px;
	}
}
.ui-lab-svg-icon{
	width: 30px;
	height: 30px;
	fill: #777777;

	cursor: pointer;
	&:hover{
		fill: #367BEB;
	}
}
</style>
<script>
export default {
	data() {
		return {
			icons: [
					'account',
					'add-store',
					'app',
					'arrowdown',
					'book',
					'checkout',
					'coupon',
					'credits',
					'currency',
					'dashboard',
					'discount',
					'disintegrate',
					'domain',
					'email',
					'general',
					'grid',
					'hdd',
					'help',
					'integration',
					'list',
					'location',
					'logout',
					'order',
					'page',
					'payment',
					'pos',
					'product-sum-x',
					'product-sum',
					'product',
					'report',
					'search',
					'setting',
					'shipping',
					'store',
					'switch-store',
					'tag',
					'taxes',
					'template',
					'theme',
					'tick',
					'users',
					'weight',
					'import',
					'export',
					'trash',
					'logo',
					'asc',
					'dsc',
					'outline-arrowdown',
					'edit',
					'confirm',
					'cancel',
					'like',
					'reset',
					'duplicate',
					'secure',
					'plus',
					'edit',
					'fullscreen',
					'fullscreen-close',
					'eye-slash',
					'eye',
					'exclamation-triangle',
					'new-tab',
					'not-secure',
					'user-tag',
					'pricing',
					'upload',
					'redirect',
					'star',
					'info',
					'wholesale',
					'collection',
					'gift',
					'cart-addon',
					'apple',
					'cherry',
					'promotion'
				],
		}
	},
	created() {
		console.log("uilab loaded")
	}
}
</script>
