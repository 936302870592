<template>
  <layout class="site-setting">
    <!-- Topbar action -->

    <form
      :class="{ 'form-loading': form_loading }"
      @submit.prevent="update"
      v-if="settings != null"
    >
      <div class="topbar-action">
        <!-- <a href="#" class="btn">Cancel</a> -->
        <button type="submit" class="btn btn-primary">{{ $tt('common.save_button') }} </button>
      </div>

      <!-- page header -->
      <header class="page-header-wrapper">
        <div class="page-header">
          <div class="page-header__main">
            <h1 class="page-header__title">{{ $tt('settings.title') }}</h1>
          </div>
          <!-- <div class="page-header__action">
						<a href="#">Create store</a>
						<a href="#">Create store</a>
          </div>-->
        </div>
      </header>

      <!-- page body/ form -->
      <div class="page-layout home-layout">
        <div class="layout layout--2">
          <section class="layout__section">
            <div class="card card--transparent">
              <div class="card__section">
                <h3>{{ $tt('settings.account.title') }}</h3>
                <p>{{ $tt('settings.account.description') }}</p>
              </div>
            </div>
          </section>
          <section class="layout__section">
            <div class="card">
              <div class="card__section">
                <div class="input-wrapper">
                  <label
                    class="input-label"
                    for="business_name"
                  >{{ $tt('settings.account.business_name') }}</label>
                  <input
                    class="input-control"
                    type="text"
                    v-model.trim="settings.business.name"
                    name="business_name"
                  >
                </div>

                <div class="input-wrapper">
                  <label for="website" class="input-label">{{ $tt('settings.account.website') }}</label>
                  <input
                    :class="{ error:errors.has('website'), 'input-control': true }"
                    type="text"
                    name="website"
                    v-model.trim="settings.business.website"
                    v-validate="'url'"
                    data-vv-validate-on="none"
                    :data-vv-as="$tt('settings.account.website')"
                  >

                  <span
                    v-if="errors.has('website')"
                    class="error-block"
                  >{{ errors.first('website') }}</span>
                </div>

                <div class="input-wrapper">
                  <label
                    for="business_email"
                    class="input-label"
                  >{{ $tt('settings.account.business_email') }}</label>
                  <input
                    :class="{ error:errors.has('business_email'), 'input-control': true }"
                    type="email"
                    name="business_email"
                    @input="settings.business.email = $event.target.value.toLowerCase()"
                    v-model.trim="settings.business.email"
                    v-validate="'email'"
                    data-vv-validate-on="none"
                    :data-vv-as="$tt('settings.account.business_email')"
                  >

                  <span
                    v-if="errors.has('business_email')"
                    class="error-block"
                  >{{ errors.first('business_email') }}</span>
                </div>

                <div class>
                  <label for="bio" class="input-label">{{ $tt('settings.account.bio') }}</label>
                  <textarea class="input-control" name="bio" v-model.trim="settings.business.bio"></textarea>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="layout layout--2">
          <section class="layout__section">
            <div class="card card--transparent">
              <div class="card__section">
                <h3>{{ $tt('settings.contact.title') }}</h3>
                <p>{{ $tt('settings.contact.description') }}</p>
              </div>
            </div>
          </section>

          <section class="layout__section">
            <div class="card">
              <div class="card__section">
                <div class="form-group form-group--2">
                  <div class="input-wrapper">
                    <label
                      for="first_name"
                      class="input-label"
                    >{{ $tt('settings.contact.first_name') }}</label>
                    <input
                      :class="{ error:errors.has('first_name'), 'input-control': true }"
                      type="text"
                      name="first_name"
                      @input="settings.contact.first_name = toCapitalize( settings.contact.first_name )"
                      v-model.trim="settings.contact.first_name"
                      v-validate="'required'"
                      data-vv-validate-on="none"
                      :data-vv-as="$tt('settings.contact.first_name')"
                    >
                    <span
                      v-if="errors.has('first_name')"
                      class="error-block"
                    >{{ errors.first('first_name') }}</span>
                  </div>

                  <div class="input-wrapper">
                    <label
                      for="last_name"
                      class="input-label"
                    >{{ $tt('settings.contact.last_name') }}</label>
                    <input
                      :class="{ error:errors.has('last_name'), 'input-control': true }"
                      type="text"
                      name="last_name"
                      @input="settings.contact.last_name = toCapitalize( settings.contact.last_name )"
                      v-model.trim="settings.contact.last_name"
                      v-validate="'required'"
                      data-vv-validate-on="none"
                      :data-vv-as="$tt('settings.contact.last_name')"
                    >
                    <span
                      v-if="errors.has('last_name')"
                      class="error-block"
                    >{{ errors.first('last_name') }}</span>
                  </div>
                </div>

                <div class="form-group form-group--2">
                  <div class="input-wrapper">
                    <label class="input-label" for="email">{{ $tt('settings.contact.email') }}</label>
                    <input
                      :class="{ error:errors.has('contact_email'), 'input-control': true }"
                      type="email"
                      name="contact_email"
                      @input="settings.contact.email = $event.target.value.toLowerCase()"
                      v-model.trim="settings.contact.email"
                      v-validate="'required|email'"
                      data-vv-validate-on="none"
                      :data-vv-as="$tt('settings.contact.email')"
                    >
                    <span
                      v-if="errors.has('contact_email')"
                      class="error-block"
                    >{{ errors.first('contact_email') }}</span>
                  </div>

                  <div class="input-wrapper">
                    <label for="phone" class="input-label">{{ $tt('settings.contact.phone') }}</label>
                    <input
                      class="input-control"
                      type="text"
                      v-model.trim="settings.contact.phone"
                      name="phone"
                    >
                  </div>
                </div>

                <div class="input-wrapper">
                  <label for="company" class="input-label">{{ $tt('settings.contact.company') }}</label>
                  <input
                    class="input-control"
                    type="text"
                    v-model.trim="settings.contact.company"
                    name="company"
                  >
                </div>

                <div class="input-wrapper">
                  <label for="address" class="input-label">{{ $tt('settings.contact.address') }}</label>
                  <input
                    class="input-control"
                    type="text"
                    v-model.trim="settings.contact.address1"
                    name="address1"
                  >
                  <input
                    class="input-control"
                    type="text"
                    v-model.trim="settings.contact.address2"
                    name="address2"
                  >
                </div>

                <div class="form-group form-group--2">
                  <div class="input-wrapper">
                    <label for="city" class="input-label">{{ $tt('settings.contact.city') }}</label>
                    <input
                      class="input-control"
                      type="text"
                      v-model.trim="settings.contact.city"
                      name="city"
                    >
                  </div>

                  <div class="input-wrapper">
                    <label for="zip" class="input-label">{{ $tt('settings.contact.zip') }}</label>
                    <input
                      class="input-control"
                      type="text"
                      v-model.trim="settings.contact.zip"
                      name="zip"
                    >
                  </div>
                </div>

                <div class="form-group form-group--2">
                  <div class="input-wrapper">
                    <label for="province" class="input-label">{{ $tt('settings.contact.province') }}</label>

                    <province-picker
                      v-model.trim="settings.contact.country_code"
                      :province_name.sync="settings.contact.province"
                      :province_code.sync="settings.contact.province_code"
                    ></province-picker>
                  </div>

                  <div class="input-wrapper">
                    <label for="country" class="input-label">{{ $tt('settings.contact.country') }}</label>
                    <country-picker v-model.trim="settings.contact.country_code"></country-picker>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="layout layout--2">
          <section class="layout__section">
            <div class="card card--transparent">
              <div class="card__section">
                <h3>{{ $tt('settings.payment.title') }}</h3>
                <p>{{ $tt('settings.payment.description') }}</p>
              </div>
            </div>
          </section>
          <section class="layout__section">
            <div class="card">
              <div class="card__section">

                <div class="account-verification">
                  <div class="input-wrapper">
                    <label for="affiliate_approved">{{ $tt("settings.payment.affiliate_approved") }}</label><br><br>
                    <span
                      v-if="settings.payment.affiliate_approved != null"
                      :class="{
                        green: settings.payment.affiliate_approved,
                        yellow: !settings.payment.affiliate_approved,
                        'label affiliate-approved': true,
                      }"
                    >
                      <template v-if="settings.payment.affiliate_approved">
                        <div class="account-verification__item badge">
                          <div class="check-icon" style="margin-right: 5px;"></div> {{ $tt("settings.payment.verified") }}
                        </div>
                      </template>
                      <template v-else>
                        <div class="account-verification__item badge">
                          {{ $tt("settings.payment.unverified") }}
                        </div>
                      </template>
                    </span>
                  </div>
                  <a
                  v-if="!settings.payment.affiliate_approved"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc3ZWnPPOnWTuQU-D3ycgBXTTAZx2IMobDMrJratWqaJnBWqw/viewform"
                  target="blank"
                  class=""
                  >{{ $tt('affiliates.tab_4_title') }}</a>
                </div>


                <div class="input-wrapper">
                  <label
                    for="paypal_account"
                    class="input-label"
                  >{{ $tt('settings.payment.paypal_account') }}</label>
                  <input
                    :class="{ error:errors.has('paypal_account'), 'input-control': true }"
                    type="email"
                    name="paypal_account"
                    @input="settings.payment.paypal_account = $event.target.value.toLowerCase()"
                    v-model.trim="settings.payment.paypal_account"
                    v-validate="'email'"
                    data-vv-validate-on="none"
                    :data-vv-as="$tt('settings.payment.paypal_account')"
                  >
                  <span
                    v-if="errors.has('paypal_account')"
                    class="error-block"
                  >{{ errors.first('paypal_account') }}</span>
                </div>
                
              </div>
            </div>
          </section>
        </div>

        <div class="layout layout--2">
          <section class="layout__section">
            <div class="card card--transparent">
              <div class="card__section">
                <h3>{{ $tt('settings.profile.title') }}</h3>
                <p>{{ $tt('settings.profile.description') }}</p>
              </div>
            </div>
          </section>
          <section class="layout__section">
            <div class="card">
              <div class="card__section">
                <div class="input-wrapper input-wrapper--small text-right">
                  <a
                    @click="show_password_dialog=true"
                  >{{ $tt('settings.profile.change_password_link') }}</a>
                </div>
                <div class="form-group form-group--2">
                  <div class>
                    <label for class="input-label">{{ $tt('settings.profile.locale') }}</label>

                    <el-select v-model.trim="settings.profile.locale" placeholder="Select">
                      <el-option value="en_US" key="en_US" label="English"></el-option>
                      <el-option value="zh_CN" key="zh_CN" label="简体中文"></el-option>
                      <el-option value="zh_TW" key="zh_TW" label="繁體中文"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </form>

    <dialog-password :open_dialog.sync="show_password_dialog"></dialog-password>
  </layout>
</template>

<script>
export default {
  data() {
    return {
      settings: null,

      show_password_dialog: false,
      form_loading: false
    };
  },

  components: {
    "dialog-password": require("./components/dialog-password.vue").default
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },

    get_setting() {
      axios
        .get("_api/settings")
        .then(response => {
          this.settings = response.data.settings;
          console.log(this.settings);
        })
        .catch(error => {
          this.error_message = error.response.data.error;
        })
        .then(() => {});
    },

    update() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;

          axios
            .post("_api/settings", this.settings)
            .then(response => {
              this.toast("success", "Update settings sucessfully");
              this.$store.commit("update");
              this.$tracking.custom_event("edited", "settings");
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    },
    toCapitalize(v) {
      return v
        .split(" ")
        .map(x => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");
    }
  },

  created() {
    this.get_setting();
  }
};
</script>

<style lang="scss" scoped>
.account-verification{
  display: flex;
  justify-content: space-between;

  &__item{
    display: flex;
  }
}
</style>
