var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      attrs: {
        action: _vm.apiUrl,
        multiple: false,
        accept: _vm.accept,
        "show-file-list": false,
        "before-upload": _vm.beforeUpload,
        "on-error": _vm.onError,
        "on-success": _vm.onSuccess,
        disabled: _vm.button_disabled
      }
    },
    [
      _c("a", { class: { btn: true, "btn-loading": _vm.button_disabled } }, [
        _vm._v(_vm._s(_vm.$tt("apps.icon.upload_button")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }