var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { staticClass: "site-auth", attrs: { blank: "" } }, [
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card__section" }, [
          _c("div", { staticClass: "site-auth__logo" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                attrs: {
                  src: "/images/partners/partners-logo.svg",
                  alt: "logo"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h1", { staticClass: "site-auth__title" }, [
            _vm._v(_vm._s(_vm.$tt("auth.signup.title")))
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              class: { "form-loading": _vm.form_loading },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.signup($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group form-group--2" }, [
                _c(
                  "div",
                  { staticClass: "input-wrapper input-wrapper--small" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.auth.first_name,
                          expression: "auth.first_name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("first_name"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "first_name",
                        placeholder: _vm.$tt(
                          "auth.signup.placeholder.first_name"
                        ),
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt(
                          "auth.signup.placeholder.first_name"
                        )
                      },
                      domProps: { value: _vm.auth.first_name },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.auth,
                              "first_name",
                              $event.target.value.trim()
                            )
                          },
                          function($event) {
                            _vm.auth.first_name = _vm.toCapitalize(
                              _vm.auth.first_name
                            )
                          }
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("first_name")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("first_name")))
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-wrapper input-wrapper--small" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.auth.last_name,
                          expression: "auth.last_name",
                          modifiers: { trim: true }
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        error: _vm.errors.has("last_name"),
                        "input-control": true
                      },
                      attrs: {
                        type: "text",
                        name: "last_name",
                        placeholder: _vm.$tt(
                          "auth.signup.placeholder.last_name"
                        ),
                        "data-vv-validate-on": "none",
                        "data-vv-as": _vm.$tt(
                          "auth.signup.placeholder.last_name"
                        )
                      },
                      domProps: { value: _vm.auth.last_name },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.auth,
                              "last_name",
                              $event.target.value.trim()
                            )
                          },
                          function($event) {
                            _vm.auth.last_name = _vm.toCapitalize(
                              _vm.auth.last_name
                            )
                          }
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("last_name")
                      ? _c("span", { staticClass: "error-block" }, [
                          _vm._v(_vm._s(_vm.errors.first("last_name")))
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.auth.email,
                      expression: "auth.email",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("email"),
                    "input-control": true
                  },
                  attrs: {
                    type: "text",
                    name: "email",
                    placeholder: _vm.$tt("auth.signup.placeholder.email"),
                    "data-vv-validate-on": "none",
                    "data-vv-as": _vm.$tt("auth.signup.placeholder.email")
                  },
                  domProps: { value: _vm.auth.email },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.auth, "email", $event.target.value.trim())
                      },
                      function($event) {
                        _vm.auth.email = $event.target.value.toLowerCase()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("email")
                  ? _c("span", { staticClass: "error-block" }, [
                      _vm._v(_vm._s(_vm.errors.first("email")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.auth.password,
                      expression: "auth.password",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:6",
                      expression: "'required|min:6'"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("password"),
                    "input-control": true
                  },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: _vm.$tt("auth.signup.placeholder.password"),
                    "data-vv-validate-on": "none",
                    "data-vv-as": _vm.$tt("auth.signup.placeholder.password")
                  },
                  domProps: { value: _vm.auth.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.auth, "password", $event.target.value.trim())
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("password")
                  ? _c("span", { staticClass: "error-block" }, [
                      _vm._v(_vm._s(_vm.errors.first("password")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-wrapper" },
                [
                  _c("country-picker", {
                    attrs: { classes: "input-large" },
                    model: {
                      value: _vm.auth.country_code,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.auth,
                          "country_code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "auth.country_code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(_vm.$tt("auth.signup.submit_button")))]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-wrapper input-wrapper--small" },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(_vm.$tt("auth.signup.back_to_login_part_1")) +
                      " "
                  ),
                  _c("router-link", { attrs: { to: { name: "login" } } }, [
                    _vm._v(_vm._s(_vm.$tt("auth.signup.back_to_login_link")))
                  ])
                ],
                1
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }