var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      class: { "form-loading": _vm.form_loading },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$tt("stores.create_dialog_title"),
            visible: _vm.show,
            "custom-class": "large"
          },
          on: {
            "update:visible": function($event) {
              _vm.show = $event
            },
            close: _vm.close
          }
        },
        [
          _c("div", { staticClass: "input-wrapper" }, [
            _c(
              "label",
              { staticClass: "input-label", attrs: { for: "name" } },
              [_vm._v(_vm._s(_vm.$tt("stores.create_dialog_store_name")))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.store.name,
                  expression: "store.name",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: { error: _vm.errors.has("name"), "input-control": true },
              attrs: {
                type: "text",
                name: "name",
                "data-vv-validate-on": "none",
                "data-vv-as": _vm.$tt("stores.create_dialog_store_name")
              },
              domProps: { value: _vm.store.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.store, "name", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("name")
              ? _c("span", { staticClass: "error-block" }, [
                  _vm._v(_vm._s(_vm.errors.first("name")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper" }, [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.$tt("stores.create_dialog_test_data")))
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "0.5rem" } }, [
              _c("div", { staticStyle: { "margin-bottom": "1rem" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.store.is_populate_test_data,
                      expression: "store.is_populate_test_data"
                    }
                  ],
                  attrs: { type: "radio", id: "disable" },
                  domProps: {
                    value: false,
                    checked: _vm._q(_vm.store.is_populate_test_data, false)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.store, "is_populate_test_data", false)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-checkmark" }),
                _vm._v(" "),
                _c("label", { attrs: { for: "disable" } }, [
                  _c("span", { staticStyle: { "font-size": "small" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$tt("stores.create_dialog_test_data_no")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticStyle: { color: "gray", "font-size": "small" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$tt("stores.create_dialog_test_data_no_desc")
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.store.is_populate_test_data,
                      expression: "store.is_populate_test_data"
                    }
                  ],
                  attrs: { type: "radio", id: "enable" },
                  domProps: {
                    value: true,
                    checked: _vm._q(_vm.store.is_populate_test_data, true)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.store, "is_populate_test_data", true)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-checkmark" }),
                _vm._v(" "),
                _c("label", { attrs: { for: "enable" } }, [
                  _c("span", { staticStyle: { "font-size": "small" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$tt("stores.create_dialog_test_data_yes")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticStyle: { color: "gray", "font-size": "small" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$tt("stores.create_dialog_test_data_yes_desc")
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$tt("stores.create_dialog_description", {
                  value1: _vm.$store.state.partner.email
                })
              )
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("a", { staticClass: "btn", on: { click: _vm.close_btn } }, [
                _vm._v(_vm._s(_vm.$tt("common.cancel_button")))
              ]),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tt("stores.create_button")) +
                      "\n      "
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }