<template>

	<el-dialog :visible="show" @close="close">

		<form :class="{ 'form-loading': form_loading }" @submit.prevent="reset">

			<div class="input-wrapper input-wrapper--small">

				<input 
				type="password" 
				name="current_password"
				:placeholder="$tt('settings.change_password.placeholder.current_password')"
				:class="{ error:errors.has('current_password'), 'input-control': true }"
				v-model.trim="auth.current_password"
				v-validate="'required|min:6'"
				data-vv-validate-on="none"		
				:data-vv-as="$tt('settings.change_password.placeholder.current_password')"		
				>

				<span v-if="errors.has('current_password')" class="error-block">{{ errors.first('current_password') }}</span>

			</div>

			<div class="input-wrapper input-wrapper--small">

				<input 
				type="password" 
				name="new_password"
				:placeholder="$tt('settings.change_password.placeholder.new_password')"
				:class="{ error:errors.has('new_password'), 'input-control': true }"
				v-model.trim="auth.new_password"
				v-validate="'required|min:6'"
				data-vv-validate-on="none"	
				:data-vv-as="$tt('settings.change_password.placeholder.new_password')"			
				>

				<span v-if="errors.has('new_password')" class="error-block">{{ errors.first('new_password') }}</span>

			</div>

			<div class="input-wrapper input-wrapper--small">

				<input 
				type="password" 
				name="new_password_confirmation"
				:placeholder="$tt('settings.change_password.placeholder.new_password_confirmation')"
				:class="{ error:errors.has('new_password_confirmation'), 'input-control': true }"
				v-model.trim="auth.new_password_confirmation"
				v-validate="{ required: true, min: 6, confirmed: auth.new_password }"		
				data-vv-delay="1000"
				:data-vv-as="$tt('settings.change_password.placeholder.new_password_confirmation')"	
				>

				<span v-if="errors.has('new_password_confirmation')" class="error-block">{{ errors.first('new_password_confirmation') }}</span>

			</div>

			<div class="input-wrapper input-wrapper--small">

				<a @click="close()" class="btn">{{ $tt('common.cancel_button') }}</a>
				<button type="submit" class="btn btn-primary">{{ $tt('settings.change_password.submit_button') }}</button>
			</div>

		</form>
	</el-dialog>


</template>

<script>
export default {
  props: ["open_dialog"],

  data: () => ({
    auth: {
      current_password: null,
      new_password: null,
      new_password_confirmation: null
    },

    show: null,
    form_loading: false
  }),

  watch: {
    open_dialog() {
      this.reset_data();
      this.show = this.open_dialog;

      if (this.show) {
      }
    }
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },

    close() {
      this.$emit("update:open_dialog", false);
    },

    close_btn() {
      this.show = false;
    },

    reset_data() {
      Object.assign(this.$data, this.$options.data());
      this.$validator.reset();
    },

    reset() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;

          axios
            .post("_api/auth/change_password", this.auth)
            .then(response => {
              this.toast(
                "success",
                this.$tt("settings.change_password.success_message")
              );

              this.close();
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    }
  }
};
</script>
