<template>
	
	<div :id="'froala_wrapper_'+_uid">
		
    <froala
    :id="'froala_'+_uid"
    :ref="'froala_'+_uid" 
    :tag="'textarea'"
    :config="config"
    v-model="body_html"
    ></froala>

	</div>

</template>

<style lang="scss">
div.froala-text-input .fr-toolbar.fr-top {
	/*display: none !important;*/
}

button.fr-command[id^="liquid_variables"] {
	float: right !important;
	width: auto !important;
	padding: 0 25px 0 15px !important;

	&:focus,
	&.fr-active,
	&.fr-selected {
		color: #666 !important;
	}
	&:focus {
		background: #fff;
	}
	&:hover {
		background: #ebebeb;
	}
}
.fr-toolbar .fr-command.fr-btn.fr-active {
	color: #666 !important;
}

.froala-text-input {
	border: 1px solid #ddd;
	border-radius: 3px;
	position: relative;
	padding-right: 103px;

	.fr-toolbar.fr-desktop.fr-top {
		z-index: 6 !important;
	}
	.fr-wrapper {
		overflow: hidden !important;
	}
	.fr-wrapper,
	.fr-element {
		max-height: 38px !important;
		border: 0 !important;
		line-height: 20px !important;
	}
	.fr-box.fr-basic .fr-wrapper {
		border-radius: 3px;
	}

	.fr-toolbar.fr-desktop.fr-top {
		position: absolute;
		right: 0;
		top: 0;
		border: 0;
		border-top-right-radius: 3px !important;
		border-bottom-right-radius: 3px !important;
	}

	button.fr-command[id^="liquid_variables"] {
		width: auto !important;
		padding: 0 25px 0 15px !important;
		height: 38px !important;
		border-left: 1px solid #dddddd;
		&:after {
			transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
		}
		&.fr-active {
			&:after {
				transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
			}
		}
	}
}

.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
    margin-left: -1px;
}
</style>

<script>
export default {
	props: ["value", "placeholder", "height"],

	data: () => ({
		body_html: '',
	}),

	computed: {
		config() {
			return {
				key: process.env.MIX_FROALA_EDITOR_KEY,
				language: this.changeLocale(Vue.config.lang),
				heightMin: 200,
				heightMax: 400,
				placeholderText: this.placeholder
					? this.placeholder
					: $.FroalaEditor.DEFAULTS.placeholderText,
				charCounterCount: false,
				quickInsertButtons: [],
				quickInsertTags: [],
				codeViewKeepActiveButtons: $.FroalaEditor.DEFAULTS.codeViewKeepActiveButtons,
				htmlAllowedAttrs: $.FroalaEditor.DEFAULTS.htmlAllowedAttrs,
				htmlAllowedTags: $.FroalaEditor.DEFAULTS.htmlAllowedTags,
				htmlDoNotWrapTags: $.FroalaEditor.DEFAULTS.htmlDoNotWrapTags,
				toolbarContainer: null,
				toolbarSticky: false,
				multiLine: true,
				htmlRemoveTags: ["script"],
				iframe: true,
				tableCellStyles: {
					"fr-highlighted": "Highlighted",
					"fr-thick": "Thick",
					"fr-borderless": "Borderless"
				},
				videoUpload: false,
				imageInsertButtons: ['imageBack', '|', 'imageByURL'],
				toolbarButtons: [
					"bold",
					"italic",
					"underline",
					"fontSize",
					"color",
					"paragraphFormat",
					"align",
					"formatOL",
					"formatUL",
					"insertHR",
					"insertLink",
					"insertVideo",
					"insertImage",
					"insertTable",
					"clearFormatting",
					"html",
					"fullscreen"
				],
				toolbarButtonsMD:[
					"fullscreen",
					"bold",
					"italic",
					"underline",
					"fontSize",
					"color",
					"paragraphFormat",
					"align",
					"formatOL",
					"formatUL",
					"insertHR",
					"insertLink",
					"insertVideo",
					"insertTable",
					"html"
				],
				toolbarButtonsSM:[
					"fullscreen",
					"bold",
					"italic",
					"underline",
					"fontSize",
					"color",
					"paragraphFormat",
					"align",
					"formatOL",
					"formatUL",
					"insertHR",
					"insertLink",
					"insertVideo",
					"insertTable",
					"html"
				],
				toolbarButtonsXS:[
					"fullscreen",
					"bold",
					"italic",
					"underline",
					"fontSize",
					"color",
					"paragraphFormat",
					"insertHR",
					"insertLink",
					"insertVideo",
					"selectAll",
				],
				events: {
					"froalaEditor.focus": (e, editor) => {
					},
					"froalaEditor.html.set": (e, editor) => {
						this.$emit("initialize", editor.html.get(true));
					}
				}
			};
		}
	},
	watch: {
		'value': function() {
			this.body_html = this.value
		},
		'body_html': function() {
			this.$emit('input', this.body_html)
		},
	},
	methods:{

	    changeLocale: function(locale) {

	      var supportedLocale = ['en_US', 'id_ID', 'ms_MY', 'zh_CN', 'zh_TW'];
	      if (supportedLocale.indexOf(locale) > -1) {
	        if (locale == 'id_ID') {
	          locale = 'id';
	       	} else {
	          locale = locale.toLowerCase();
	       	}
	      }
	      return locale
	    },

	},

	created() {
		this.config.htmlAllowedTags.push("meta");
		this.config.htmlAllowedTags.push("style");
		this.config.htmlAllowedAttrs.push("aria-.*");
	}
};
</script>
