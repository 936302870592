var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "site-setting" },
    [
      _vm.settings != null
        ? _c(
            "form",
            {
              class: { "form-loading": _vm.form_loading },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.update($event)
                }
              }
            },
            [
              _c("div", { staticClass: "topbar-action" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(_vm.$tt("common.save_button")) + " ")]
                )
              ]),
              _vm._v(" "),
              _c("header", { staticClass: "page-header-wrapper" }, [
                _c("div", { staticClass: "page-header" }, [
                  _c("div", { staticClass: "page-header__main" }, [
                    _c("h1", { staticClass: "page-header__title" }, [
                      _vm._v(_vm._s(_vm.$tt("settings.title")))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "page-layout home-layout" }, [
                _c("div", { staticClass: "layout layout--2" }, [
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card card--transparent" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$tt("settings.account.title")))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$tt("settings.account.description"))
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "business_name" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tt("settings.account.business_name")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.business.name,
                                expression: "settings.business.name",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "input-control",
                            attrs: { type: "text", name: "business_name" },
                            domProps: { value: _vm.settings.business.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings.business,
                                  "name",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "website" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$tt("settings.account.website"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.business.website,
                                expression: "settings.business.website",
                                modifiers: { trim: true }
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "url",
                                expression: "'url'"
                              }
                            ],
                            class: {
                              error: _vm.errors.has("website"),
                              "input-control": true
                            },
                            attrs: {
                              type: "text",
                              name: "website",
                              "data-vv-validate-on": "none",
                              "data-vv-as": _vm.$tt("settings.account.website")
                            },
                            domProps: { value: _vm.settings.business.website },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings.business,
                                  "website",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("website")
                            ? _c("span", { staticClass: "error-block" }, [
                                _vm._v(_vm._s(_vm.errors.first("website")))
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "business_email" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tt("settings.account.business_email")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.business.email,
                                expression: "settings.business.email",
                                modifiers: { trim: true }
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "email",
                                expression: "'email'"
                              }
                            ],
                            class: {
                              error: _vm.errors.has("business_email"),
                              "input-control": true
                            },
                            attrs: {
                              type: "email",
                              name: "business_email",
                              "data-vv-validate-on": "none",
                              "data-vv-as": _vm.$tt(
                                "settings.account.business_email"
                              )
                            },
                            domProps: { value: _vm.settings.business.email },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settings.business,
                                    "email",
                                    $event.target.value.trim()
                                  )
                                },
                                function($event) {
                                  _vm.settings.business.email = $event.target.value.toLowerCase()
                                }
                              ],
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("business_email")
                            ? _c("span", { staticClass: "error-block" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("business_email"))
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", {}, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "bio" }
                            },
                            [_vm._v(_vm._s(_vm.$tt("settings.account.bio")))]
                          ),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.business.bio,
                                expression: "settings.business.bio",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "input-control",
                            attrs: { name: "bio" },
                            domProps: { value: _vm.settings.business.bio },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings.business,
                                  "bio",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "layout layout--2" }, [
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card card--transparent" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$tt("settings.contact.title")))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$tt("settings.contact.description"))
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("div", { staticClass: "form-group form-group--2" }, [
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "first_name" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$tt("settings.contact.first_name"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settings.contact.first_name,
                                  expression: "settings.contact.first_name",
                                  modifiers: { trim: true }
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              class: {
                                error: _vm.errors.has("first_name"),
                                "input-control": true
                              },
                              attrs: {
                                type: "text",
                                name: "first_name",
                                "data-vv-validate-on": "none",
                                "data-vv-as": _vm.$tt(
                                  "settings.contact.first_name"
                                )
                              },
                              domProps: {
                                value: _vm.settings.contact.first_name
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.settings.contact,
                                      "first_name",
                                      $event.target.value.trim()
                                    )
                                  },
                                  function($event) {
                                    _vm.settings.contact.first_name = _vm.toCapitalize(
                                      _vm.settings.contact.first_name
                                    )
                                  }
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.has("first_name")
                              ? _c("span", { staticClass: "error-block" }, [
                                  _vm._v(_vm._s(_vm.errors.first("first_name")))
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "last_name" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$tt("settings.contact.last_name"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settings.contact.last_name,
                                  expression: "settings.contact.last_name",
                                  modifiers: { trim: true }
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              class: {
                                error: _vm.errors.has("last_name"),
                                "input-control": true
                              },
                              attrs: {
                                type: "text",
                                name: "last_name",
                                "data-vv-validate-on": "none",
                                "data-vv-as": _vm.$tt(
                                  "settings.contact.last_name"
                                )
                              },
                              domProps: {
                                value: _vm.settings.contact.last_name
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.settings.contact,
                                      "last_name",
                                      $event.target.value.trim()
                                    )
                                  },
                                  function($event) {
                                    _vm.settings.contact.last_name = _vm.toCapitalize(
                                      _vm.settings.contact.last_name
                                    )
                                  }
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.has("last_name")
                              ? _c("span", { staticClass: "error-block" }, [
                                  _vm._v(_vm._s(_vm.errors.first("last_name")))
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group form-group--2" }, [
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "email" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$tt("settings.contact.email"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settings.contact.email,
                                  expression: "settings.contact.email",
                                  modifiers: { trim: true }
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'"
                                }
                              ],
                              class: {
                                error: _vm.errors.has("contact_email"),
                                "input-control": true
                              },
                              attrs: {
                                type: "email",
                                name: "contact_email",
                                "data-vv-validate-on": "none",
                                "data-vv-as": _vm.$tt("settings.contact.email")
                              },
                              domProps: { value: _vm.settings.contact.email },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.settings.contact,
                                      "email",
                                      $event.target.value.trim()
                                    )
                                  },
                                  function($event) {
                                    _vm.settings.contact.email = $event.target.value.toLowerCase()
                                  }
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.has("contact_email")
                              ? _c("span", { staticClass: "error-block" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("contact_email"))
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "phone" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$tt("settings.contact.phone"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settings.contact.phone,
                                  expression: "settings.contact.phone",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "input-control",
                              attrs: { type: "text", name: "phone" },
                              domProps: { value: _vm.settings.contact.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settings.contact,
                                    "phone",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "company" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$tt("settings.contact.company"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.contact.company,
                                expression: "settings.contact.company",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "input-control",
                            attrs: { type: "text", name: "company" },
                            domProps: { value: _vm.settings.contact.company },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings.contact,
                                  "company",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "address" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$tt("settings.contact.address"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.contact.address1,
                                expression: "settings.contact.address1",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "input-control",
                            attrs: { type: "text", name: "address1" },
                            domProps: { value: _vm.settings.contact.address1 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings.contact,
                                  "address1",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.contact.address2,
                                expression: "settings.contact.address2",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "input-control",
                            attrs: { type: "text", name: "address2" },
                            domProps: { value: _vm.settings.contact.address2 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings.contact,
                                  "address2",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group form-group--2" }, [
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "city" }
                              },
                              [_vm._v(_vm._s(_vm.$tt("settings.contact.city")))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settings.contact.city,
                                  expression: "settings.contact.city",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "input-control",
                              attrs: { type: "text", name: "city" },
                              domProps: { value: _vm.settings.contact.city },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settings.contact,
                                    "city",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "zip" }
                              },
                              [_vm._v(_vm._s(_vm.$tt("settings.contact.zip")))]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.settings.contact.zip,
                                  expression: "settings.contact.zip",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "input-control",
                              attrs: { type: "text", name: "zip" },
                              domProps: { value: _vm.settings.contact.zip },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settings.contact,
                                    "zip",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group form-group--2" }, [
                          _c(
                            "div",
                            { staticClass: "input-wrapper" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "input-label",
                                  attrs: { for: "province" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$tt("settings.contact.province"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("province-picker", {
                                attrs: {
                                  province_name: _vm.settings.contact.province,
                                  province_code:
                                    _vm.settings.contact.province_code
                                },
                                on: {
                                  "update:province_name": function($event) {
                                    return _vm.$set(
                                      _vm.settings.contact,
                                      "province",
                                      $event
                                    )
                                  },
                                  "update:province_code": function($event) {
                                    return _vm.$set(
                                      _vm.settings.contact,
                                      "province_code",
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.settings.contact.country_code,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.settings.contact,
                                      "country_code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "settings.contact.country_code"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "input-wrapper" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "input-label",
                                  attrs: { for: "country" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$tt("settings.contact.country"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("country-picker", {
                                model: {
                                  value: _vm.settings.contact.country_code,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.settings.contact,
                                      "country_code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "settings.contact.country_code"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "layout layout--2" }, [
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card card--transparent" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$tt("settings.payment.title")))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$tt("settings.payment.description"))
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("div", { staticClass: "account-verification" }, [
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "label",
                              { attrs: { for: "affiliate_approved" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tt(
                                      "settings.payment.affiliate_approved"
                                    )
                                  )
                                )
                              ]
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _vm.settings.payment.affiliate_approved != null
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      green:
                                        _vm.settings.payment.affiliate_approved,
                                      yellow: !_vm.settings.payment
                                        .affiliate_approved,
                                      "label affiliate-approved": true
                                    }
                                  },
                                  [
                                    _vm.settings.payment.affiliate_approved
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "account-verification__item badge"
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "check-icon",
                                                staticStyle: {
                                                  "margin-right": "5px"
                                                }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tt(
                                                      "settings.payment.verified"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "account-verification__item badge"
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.$tt(
                                                      "settings.payment.unverified"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.settings.payment.affiliate_approved
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://docs.google.com/forms/d/e/1FAIpQLSc3ZWnPPOnWTuQU-D3ycgBXTTAZx2IMobDMrJratWqaJnBWqw/viewform",
                                    target: "blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$tt("affiliates.tab_4_title"))
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "label",
                            {
                              staticClass: "input-label",
                              attrs: { for: "paypal_account" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tt("settings.payment.paypal_account")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.settings.payment.paypal_account,
                                expression: "settings.payment.paypal_account",
                                modifiers: { trim: true }
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "email",
                                expression: "'email'"
                              }
                            ],
                            class: {
                              error: _vm.errors.has("paypal_account"),
                              "input-control": true
                            },
                            attrs: {
                              type: "email",
                              name: "paypal_account",
                              "data-vv-validate-on": "none",
                              "data-vv-as": _vm.$tt(
                                "settings.payment.paypal_account"
                              )
                            },
                            domProps: {
                              value: _vm.settings.payment.paypal_account
                            },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.settings.payment,
                                    "paypal_account",
                                    $event.target.value.trim()
                                  )
                                },
                                function($event) {
                                  _vm.settings.payment.paypal_account = $event.target.value.toLowerCase()
                                }
                              ],
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("paypal_account")
                            ? _c("span", { staticClass: "error-block" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("paypal_account"))
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "layout layout--2" }, [
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card card--transparent" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$tt("settings.profile.title")))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$tt("settings.profile.description"))
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "layout__section" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card__section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-wrapper input-wrapper--small text-right"
                          },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.show_password_dialog = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tt(
                                      "settings.profile.change_password_link"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group form-group--2" }, [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "input-label",
                                  attrs: { for: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$tt("settings.profile.locale"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "Select" },
                                  model: {
                                    value: _vm.settings.profile.locale,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.settings.profile,
                                        "locale",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "settings.profile.locale"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    key: "en_US",
                                    attrs: { value: "en_US", label: "English" }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "zh_CN",
                                    attrs: { value: "zh_CN", label: "简体中文" }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "zh_TW",
                                    attrs: { value: "zh_TW", label: "繁體中文" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("dialog-password", {
        attrs: { open_dialog: _vm.show_password_dialog },
        on: {
          "update:open_dialog": function($event) {
            _vm.show_password_dialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }