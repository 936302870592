<template>
  <form @submit.prevent="submit" :class="{ 'form-loading': form_loading }">
    <el-dialog
      :title="$tt('stores.create_dialog_title')"
      :visible.sync="show"
      @close="close"
      custom-class="large"
    >
      <div class="input-wrapper">
        <label class="input-label" for="name">{{
          $tt("stores.create_dialog_store_name")
        }}</label>
        <input
          :class="{ error: errors.has('name'), 'input-control': true }"
          type="text"
          name="name"
          v-model.trim="store.name"
          v-validate="'required'"
          data-vv-validate-on="none"
          :data-vv-as="$tt('stores.create_dialog_store_name')"
        />

        <span v-if="errors.has('name')" class="error-block">{{
          errors.first("name")
        }}</span>
      </div>

      <div class="input-wrapper">
        <label class="input-label">{{
          $tt("stores.create_dialog_test_data")
        }}</label>

        <div style="margin-top: 0.5rem">
          <div style="margin-bottom: 1rem">
            <input
              type="radio"
              v-model="store.is_populate_test_data"
              :value="false"
              id="disable"
            />
            <span class="input-checkmark"></span>
            <label for="disable">
              <span style="font-size: small">
                {{ $tt("stores.create_dialog_test_data_no") }}
              </span>
              <p style="color: gray; font-size: small">
                {{ $tt("stores.create_dialog_test_data_no_desc") }}
              </p>
            </label>
          </div>

          <div>
            <input
              type="radio"
              v-model="store.is_populate_test_data"
              :value="true"
              id="enable"
            />
            <span class="input-checkmark"></span>
            <label for="enable">
              <span style="font-size: small">
                {{ $tt("stores.create_dialog_test_data_yes") }}
              </span>
              <p style="color: gray; font-size: small">
                {{ $tt("stores.create_dialog_test_data_yes_desc") }}
              </p>
            </label>
          </div>
        </div>
      </div>

      <p
        v-html="
          $tt('stores.create_dialog_description', {
            value1: $store.state.partner.email,
          })
        "
      ></p>
      <span slot="footer" class="dialog-footer">
        <a class="btn" @click="close_btn">{{ $tt("common.cancel_button") }}</a>
        <button class="btn btn-primary" type="submit">
          {{ $tt("stores.create_button") }}
        </button>
      </span>
    </el-dialog>
  </form>
</template>

<script>
export default {
  props: ["open_dialog"],
  data: () => ({
    store: { name: "", is_populate_test_data: false },
    form_loading: false,
    show: null,
  }),

  watch: {
    open_dialog() {
      this.reset_data();
      this.show = this.open_dialog;
    },
  },
  methods: {
    close() {
      this.$emit("update:open_dialog", false);
    },

    close_btn() {
      this.show = false;
    },

    reset_data() {
      Object.assign(this.$data, this.$options.data());
    },

    toast(type, message) {
      this.$message({
        message: message,
        type: type,
      });
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (!this.form_loading && result) {
          this.form_loading = true;

          axios
            .post("_api/stores", this.store)
            .then((response) => {
              if (response.data.status) {
                this.toast(
                  "success",
                  this.$tt("stores.create_success_message")
                );
                this.close();
                this.$emit("callback", true);
              }
            })
            .catch((error) => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    },
  },
};
</script>
