var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { staticClass: "site-auth", attrs: { blank: "" } }, [
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card__section" }, [
          _c("div", { staticClass: "site-auth__logo" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                attrs: {
                  src: "/images/partners/partners-logo.svg",
                  alt: "logo"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h1", { staticClass: "site-auth__title" }, [
            _vm._v(_vm._s(_vm.$tt("auth.login.title")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
            _c("span", {
              attrs: { id: "paypal_login" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "form",
            {
              class: { "form-loading": _vm.form_loading },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.login($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.auth.email,
                      expression: "auth.email",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("email"),
                    "input-control": true
                  },
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: _vm.$tt("auth.login.placeholder.email"),
                    "data-vv-validate-on": "none",
                    "data-vv-as": _vm.$tt("auth.login.placeholder.email")
                  },
                  domProps: { value: _vm.auth.email },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.auth, "email", $event.target.value.trim())
                      },
                      function($event) {
                        _vm.auth.email = $event.target.value.toLowerCase()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("email")
                  ? _c("span", { staticClass: "error-block" }, [
                      _vm._v(_vm._s(_vm.errors.first("email")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.auth.password,
                      expression: "auth.password",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("password"),
                    "input-control": true
                  },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: _vm.$tt("auth.login.placeholder.password"),
                    "data-vv-validate-on": "none",
                    "data-vv-as": _vm.$tt("auth.login.placeholder.password")
                  },
                  domProps: { value: _vm.auth.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.auth, "password", $event.target.value.trim())
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password"),
                        expression: "errors.has('password')"
                      }
                    ],
                    staticClass: "error-block"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("password")))]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group form-group--spaced" }, [
                _c(
                  "div",
                  { staticClass: "input-wrapper input-wrapper--small" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.auth.is_remember,
                          expression: "auth.is_remember"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        name: "is_remember",
                        id: "is_remember"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.auth.is_remember)
                          ? _vm._i(_vm.auth.is_remember, null) > -1
                          : _vm.auth.is_remember
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.auth.is_remember,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.auth,
                                  "is_remember",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.auth,
                                  "is_remember",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.auth, "is_remember", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-checkmark" }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "is_remember" } }, [
                      _vm._v(_vm._s(_vm.$tt("auth.login.remember_me")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-wrapper input-wrapper--small" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "forgot-password" } } },
                      [
                        _vm._v(
                          _vm._s(_vm.$tt("auth.login.forgot_password_link"))
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(_vm.$tt("auth.login.submit_button")))]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-wrapper input-wrapper--small" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tt("auth.login.create_account_part_1")) +
                      "\n            "
                  ),
                  _c("router-link", { attrs: { to: { name: "signup" } } }, [
                    _vm._v(_vm._s(_vm.$tt("auth.login.create_account_link")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", { staticClass: "terms" }, [
                _c("small", [
                  _vm._v(
                    "\n              By signing up EasyStore Partner Program, you agree to our\n              "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://support.easystore.co/en/article/easystore-partners-terms-conditions-1mnybnt/"
                      }
                    },
                    [_vm._v("Terms & Condition")]
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }