var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-frame" },
    [
      !_vm.blank ? _c("sidebar") : _vm._e(),
      _vm._v(" "),
      !_vm.blank ? _c("topbar") : _vm._e(),
      _vm._v(" "),
      _c(
        "main",
        {
          staticClass: "page-frame__main",
          class: { "page-frame--full": _vm.blank }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }