import VueI18n from "vue-i18n";
Vue.use(VueI18n);

Vue.config.lang = "en_US";
Vue.config.fallbackLang = "en_US";

import element_en_US from "element-ui/lib/locale/lang/en";
import element_zh_CN from "element-ui/lib/locale/lang/zh-CN";
import element_zh_TW from "element-ui/lib/locale/lang/zh-TW";
import locale_en_US from "./locale/en_US.json";

Object.assign(locale_en_US.el, element_en_US.el);

Vue.locale("en_US", locale_en_US);

window.locale = "en_US";

window.setLocale = function(locale) {
  if (locale != window.locale) {
    axios.get("/_api/locale/" + locale).then(response => {
      switch (locale) {
        case "zh_TW":
          Object.assign(response.data.el, element_zh_TW.el);
          break;
        case "zh_CN":
          Object.assign(response.data.el, element_zh_CN.el);
          break;
        case "id_ID":
          Object.assign(response.data.el, element_id_ID.el);
          break;
        default:
          Object.assign(response.data.el, element_en_US.el);
      }

      Vue.locale(locale, response.data);
      Vue.config.lang = locale;
      window.locale = locale;

      if (locale == "empty") {
        Vue.config.fallbackLang = "";
        Vue.config.missingHandler = function(lang, key, vm) {
          //msg suppressed
        };
      } else {
        Vue.config.missingHandler = null;
      }
    });
  }
};

Vue.prototype.$tt = function(key, firstParam = null, secondParam = null, thirdParam = null) {
  // return this.$t(value) + ' ('+value+')'
  // return this.$t(key, named)
  var count = firstParam;
  var named = secondParam;
  if (typeof firstParam == "number") {
    var prefix = "";
    var suffix = "";
    if (["en_US", "id_ID"].indexOf(Vue.config.lang) > -1) {
      if (thirdParam == "s") {
        prefix = " ";
      } else if (thirdParam == "e") {
        suffix = " ";
      } else if (thirdParam == "b") {
        prefix = " ";
        suffix = " ";
      }
    }
    return prefix + this.$tc(key, count, named) + suffix;
  } else {
    named = firstParam;
    return this.$t(key, named);
  }
};

Vue.prototype.$tt_sc = function(key, firstParam = null, secondParam = null) {
  var result = this.$tt(key, firstParam, secondParam);
  return result.charAt(0).toUpperCase() + result.slice(1);
};
