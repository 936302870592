<template>

  <el-select :class="classes" :popper-class="popperclass" :size="sizes" filterable v-model="country_code" auto-complete="es-country" :placeholder="$tt('picker.country.placeholder')" default-first-option>

    <el-option v-for="c in countries" :value="c.country_code" :key="c.country_code" :label="c.country_name"></el-option>

  </el-select>

</template>

<script>
module.exports = {
  props: ["value", "classes", "popperclass", "sizes"],
  data: () => ({
    loading: true,
    countries: [],
    country_code: null
  }),
  watch: {
    value: function() {
      if (this.countries.length > 0) {
        this.country_code = this.value;
      }
    },
    country_code: function() {
      var ix = _.findIndex(this.countries, { country_code: this.country_code });
      var country_name = this.countries[ix].country_name;
      // console.log(this.country_code, country_name)
      this.$emit("input", this.country_code);
      this.$emit("update:country_name", country_name);
    }
  },

  methods: {
    reset() {
      if (this.countries.length == 0) {
        axios
          .get("/_api/references/countries")
          .then(response => {
            this.country_code = this.value;
            this.countries = response.data.countries;
            this.loading = false;
          })
          .catch(e => {
            console.log("errors");
          });
      }
    }
  },

  created() {
    this.reset();
  }
};
</script>
