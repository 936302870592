<template>

  <div>

    <el-date-picker
      v-model="start_end_array"
      type="daterange"
      :editable="false"
      popper-class="daterange"
      range-separator=" — "
      start-placeholder="Start date"
      end-placeholder="End date"
      :picker-options="{

        firstDayOfWeek: 1,
        disabledDate: function(time){
          return time.getTime() > Date.now()
        },

      }"
    ></el-date-picker>

  </div>

</template>

<script>
module.exports = {
  props: ["start_at", "end_at"],
  data: () => ({
    start_end_array: ""
  }),

  methods: {
    today() {
      var time = new Date();
      return window._moment.utc(time).format("YYYY-MM-DD");
    }
  },
  watch: {
    start_end_array: function() {
      if (this.start_end_array.length == 2) {
        this.$emit(
          "update:start_at",
          window._moment.utc(this.start_end_array[0]).format("YYYY-MM-DD")
        );
        this.$emit(
          "update:end_at",
          window._moment.utc(this.start_end_array[1]).format("YYYY-MM-DD")
        );
      }
    }
  },
  created() {
    var today = window._moment(this.today());
    var last_30d = window._moment(this.today()).subtract(30, "days");
    var start = this.start ? this.start : last_30d;
    var end = this.end ? this.end : today;
    this.start_end_array = [start, end];
    this.$emit("update:start_at", this.start_end_array[0]);
    this.$emit("update:end_at", this.start_end_array[1]);
  }
};
</script>
