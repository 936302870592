<template>
	
	<layout>

		<form :class="{ 'form-loading': form_loading }" @submit.prevent="update">

			<div class="topbar-action">
				<router-link :to="{ name: 'payments' }" class="btn">{{ $tt('common.cancel_button') }}</router-link>
				<button type="submit" class="btn btn-primary">{{ $tt('common.save_button') }}</button>
			</div>

			<header class="page-header-wrapper">
				<div class="page-header">
					<div class="page-header__main">
						<h1 class="page-header__title">{{ $tt('payments.title') }} / {{ this.payment.id == null ? $tt('payments.create_payment') : this.payment.name }}</h1>

						<div class="page-header__action app-header__action">
							<div class="action-link">
								<a v-if="payment.id != null" @click.native="$tracking.custom_event('view', 'payment')" :href="payment_gateway_url" target="blank">
									<svg class="svg-icon"><use xlink:href="#icon-new-tab"></use></svg>
									<span>{{ $tt('payments.view_payment') }}</span>
								</a> 
							</div>
							<div v-if="payment.id != null" class="action-link">
								<delete-button
							
								:text="$tt('payments.delete_payment')"
								:icon="'trash'"
								:form_loading="form_loading"
								:api="{
									url: '/_api/payments/'+payment.id,
									redirect: { path: '/payments', query: null }
								}"
								:dialog="{
									title: $tt('payments.delete_dialog_title', {value1: payment.name }),
									description: $tt('payments.delete_dialog_description'),
									button: {
										cancel: $tt('common.cancel_button'),
										confirm: $tt('payments.delete_button'),
									},
									message: {
										success: $tt('payments.delete_dialog_success_msg'),
										error: $tt('payments.delete_dialog_error_msg'),
									},
								}"
								></delete-button>
							</div>
							<div v-if="payment.id != null && payment.is_approved == 1" class="action-link">

								<publish-button

								:text="payment.is_published ? $tt('payments.unpublish_payment') : $tt('payments.publish_payment')"
								:icon="payment.is_published ? 'cancel' : 'confirm'"
								:form_loading="form_loading"
								:api="{
									url: payment.is_published ? '/_api/payments/'+payment.id+'/unpublish' : '/_api/payments/'+payment.id+'/publish',
									redirect: { path: '/payments/'+payment.id, query: null }
								}"
								:dialog="{
									title: payment.is_published ? $tt('payments.unpublish_dialog_title', {value1: payment.name }) : $tt('payments.publish_dialog_title', {value1: payment.name }),
									description: payment.is_published ? $tt('payments.unpublish_dialog_description') : $tt('payments.publish_dialog_description'),
									button: {
										cancel: $tt('common.cancel_button'),
										confirm: payment.is_published ? $tt('payments.unpublish_button') : $tt('payments.publish_button'),
									},
									message: {
										success: payment.is_published ? $tt('payments.unpublish_dialog_success_msg') : $tt('payments.publish_dialog_success_msg'),
										error: payment.is_published ? $tt('payments.unpublish_dialog_success_msg') : $tt('payments.publish_dialog_error_msg'),
									},
								}"

								></publish-button>
								
							</div>

							<div v-if="payment.id != null && (payment.is_approved == 2 || payment.is_approved == 0)" class="action-link">


								<review-button

								:text="$tt('payments.review_payment')"
								:form_loading="form_loading"
								:api="{
									url: '/_api/payments/'+payment.id+'/submit',
									redirect: { path: '/payments', query: null }
								}"
								:dialog="{
									title: $tt('payments.review_dialog_title', {value1: payment.name }),
									description: $tt('payments.review_dialog_description'),
									button: {
										cancel: $tt('common.cancel_button'),
										confirm: $tt('payments.review_button'),
									},
									message: {
										success: $tt('payments.review_dialog_success_msg'),
										error: $tt('payments.review_dialog_error_msg'),
									},
								}"								

								></review-button>

							</div>

						</div>

					</div>
				</div>
			</header>

			<div class="page-layout home-layout">

				<!-- payment information -->
				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('payments.payment.title') }}</h3>
								<p>{{ $tt('payments.payment.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">
						<div class="card">
							<div class="card__section">
								<div class="input-wrapper">
									<label class="input-label" for="name">{{ $tt('payments.payment.name') }}</label>
									<input 
									:class="{ error:errors.has('name'), 'input-control': true }"
									type="text" 
									name="name" 
									v-model.trim="payment.name"
									v-validate="'required'"
									data-vv-validate-on="none"		
									:data-vv-as="$tt('payments.payment.name')"	
									>

									<span v-if="errors.has('name')" class="error-block">{{ errors.first('name') }}</span>
								</div>								

								<div class="input-wrapper">
									<label class="input-label" for="description">{{ $tt('payments.payment.description') }}</label>
									<textarea 
									:class="{ error:errors.has('description'), 'input-control': true }"
									name="description" 
									v-model.trim="payment.description"
									v-validate="'max:120'"
									data-vv-delay="550"
									:data-vv-as="$tt('payments.payment.description')"
									></textarea>

									<span v-if="!errors.has('description')" class="help-block text-right">{{ description_remaining }}</span>

									<span v-if="errors.has('description')" class="error-block">{{ errors.first('description') }}</span>

								</div>	

								<div class="input-wrapper">

									<label class="input-label" for="">{{ $tt('payments.payment.guideline') }}</label>

									<editor v-model="payment.guideline" :placeholder="$tt('payments.payment.guideline_placeholder')" ></editor>

								</div>	
						

								<div class="input-wrapper">
									<label class="input-label" for="hosted_payment_url">{{ $tt('payments.payment.hosted_payment_url') }}</label>
									<input 
									:class="{ error:errors.has('hosted_payment_url'), 'input-control': true }"
									type="text" 
									name="hosted_payment_url" 
									v-model.trim="payment.hosted_payment_url"
									v-validate="{ url: { require_protocol: true } }"
									data-vv-validate-on="none"
									:data-vv-as="$tt('payments.payment.hosted_payment_url')"
									:placeholder="$tt('payments.payment.hosted_payment_url_placeholder')"
									>

									<span v-if="errors.has('hosted_payment_url')" class="error-block">{{ errors.first('hosted_payment_url') }}</span>
									<span class="help-block">{{ $tt('payments.payment.hosted_payment_url_description') }}</span>
								</div>	
						

							</div>

						</div>

					</section>

				</div>

				<!-- payment credentials  -->
				<div class="layout layout--2">
					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('payments.credential.title') }}</h3>
								<p>{{ $tt('payments.credential.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">
						<div class="card">
							<div class="card__section">
								<div class="form-group form-group--2">
									<div class="input-wrapper">
										<label for="account1" class="input-label">{{ $tt('payments.credential.account1') }}</label>
										<input
										class="input-control"
										type="text"
										v-model.trim="payment.account1"
										name="account1"
										>
									</div>

									<div class="input-wrapper">
										<label for="secret1" class="input-label">{{ $tt('payments.credential.secret1') }}</label>
										<input
										class="input-control"
										type="text"
										v-model.trim="payment.secret1"
										name="secret1"
										>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>

				<!-- payment supported  -->
				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('payments.supported.title') }}</h3>
								<p>{{ $tt('payments.supported.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">
						<div class="card">
							<div class="card__section">
								<div class="input-wrapper">
									<div>{{ $tt('payments.supported.country') }}</div> <br>

									<input type="checkbox" id="my" value="MY" v-model="payment.countries" />
									<span class="input-checkmark"></span>
									<label for="my">Malaysia</label>

									<input type="checkbox" id="sg" value="SG" v-model="payment.countries" />
									<span class="input-checkmark"></span>
									<label for="sg">Singapore</label>

									<input type="checkbox" id="tw" value="TW" v-model="payment.countries" />
									<span class="input-checkmark"></span>
									<label for="tw">Taiwan</label>

									<input type="checkbox" id="th" value="TH" v-model="payment.countries" />
									<span class="input-checkmark"></span>
									<label for="th">Thailand</label>

									<input type="checkbox" id="us" value="US" v-model="payment.countries" />
									<span class="input-checkmark"></span>
									<label for="us">United State</label>
								</div>

								<div class="input-wrapper">
									<div>{{ $tt('payments.supported.currency') }}</div><br>

									<input type="checkbox" id="myr" value="MYR" v-model="payment.currencies" />
									<span class="input-checkmark"></span>
									<label for="myr">MYR</label>

									<input type="checkbox" id="sgd" value="SGD" v-model="payment.currencies" />
									<span class="input-checkmark"></span>
									<label for="sgd">SGD</label>

									<input type="checkbox" id="twd" value="TWD" v-model="payment.currencies" />
									<span class="input-checkmark"></span>
									<label for="twd">TWD</label>

									<input type="checkbox" id="thb" value="THB" v-model="payment.currencies" />
									<span class="input-checkmark"></span>
									<label for="thb">THB</label>

									<input type="checkbox" id="usd" value="USD" v-model="payment.currencies" />
									<span class="input-checkmark"></span>
									<label for="usd">USD</label>
								</div>
							</div>
						</div>
					</section>

				</div>

				<!-- payment icon  -->
				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('payments.icon.title') }}</h3>
								<p>{{ $tt('payments.icon.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">

						<div class="card">

							<div class="card__section">

								<div class="input-wrapper">
									<label class="input-label">{{ $tt('payments.icon.icon') }}</label>
									<div class="form-group form-group--spaced upload-wrapper">
										<div class="app-icon__wrapper">
											<img :src="payment.img_icon_url" alt="icon">
										</div>
										
										<div class="upload-cta">

											<upload-button
											:apiUrl="'/_api/images/upload?type=app_icon'"
											:mbLimit="'10'"
											:accept="'image/png, image/gif, image/jpeg'"
											:form_loading.sync="form_loading"
											:image_url.sync="payment.img_icon_url"
											></upload-button>

										</div>
									</div>
									<span class="help-block">
										{{ $tt('payments.icon.icon_description') }}
									</span>
								</div>
							</div>
						</div>

					</section>

				</div>

			</div>


		</form>

	</layout>

</template>
<style lang="scss">
.app-header__action {
  margin-left: -6px;
}
</style>
<script>
export default {
  data() {
    return {
      payment: {
        id: null,
        name: "",
        description: "",
        guideline: "",
        hosted_payment_url: "",
		country_code: "",
		currency_code: "",
		credential_fields: "",
		countries: [],
	  	currencies: [],
        img_banner_url:
          "https://s3-ap-southeast-1.amazonaws.com/eesb.public/images/apps/apps_default.png",
        img_icon_url:
          "https://s3-ap-southeast-1.amazonaws.com/eesb.public/images/apps/apps_default-sq.png"
      },
      max_char: 120,
      hide_secret: true,
      form_loading: false,
    };
  },

  computed: {
    payment_gateway_url() {
    //   return process.env.MIX_WEBSITE_DOMAIN + "/payments/" + this.payment.handle;
	return "https://admin.easystore.blue/settings/gateway/" + this.payment.code;
    },

    description_remaining() {
      var remaining = this.max_char;

      if (
        this.payment.description != null &&
        this.payment.description.length > 0
      ) {
        remaining = this.max_char - this.payment.description.length;
      }

      remaining = remaining < 0 ? 0 : remaining;

      return this.$tt("payments.payment.remaining_char", { value1: remaining });
    }
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },

    update() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;
			console.log('update',this.payment);
          if (this.payment.id != null) {
            var url = "/_api/payments/" + this.payment.id;
          } else {
            var url = "/_api/payments";
          }

          axios
            .post(url, this.payment)
            .then(response => {
              if (this.payment.id != null) {
                Object.assign(this.payment, response.data.payment);

                this.$tracking.custom_event("edited", "payment");

                this.toast("success", this.$tt("payments.update_success_message"));

                window.location.reload()

              } else {
                this.toast("success", this.$tt("payments.create_success_message"));

                this.$tracking.custom_event("created", "payment");

                this.$router.push("/payments/" + response.data.payment.id);
              }
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    },
    retrieve() {
      axios
        .get("/_api/payments/" + this.$route.params.payment_id)
        .then(response => {
          this.payment = response.data.payment;

          this.$tracking.custom_event("edit", "payment");
        })
        .catch(error => {
          this.$router.push("/payments");
        })
        .then(() => {});
    }
  },
  created() {
    if (typeof this.$route.params.payment_id !== "undefined") {
      this.retrieve();
    }
  }
};
</script>
