<template>

	<div class="">
		<el-popover v-model="profile_dropdown" ref="popover1" placement="bottom" popper-class="popover-actions" transition="slide-fade">
	         <ul class="action-list" @click="profile_dropdown = false">

	             <li class="icon-button">
	             	<router-link class="icon-button small" :to="{ name: 'settings' }">
	             	<svg class="svg-icon"><use xlink:href="#icon-setting"></use></svg><span>Settings</span>
		             </router-link>
	             </li>
	             <li class="">
	             	<a class="icon-button small" @click="logout">
	             	<svg class="svg-icon"><use xlink:href="#icon-logout"></use></svg><span>Logout</span>
	             	</a>
	             </li>
	            <!-- <li class="separator"></li> -->
	         </ul>
	    </el-popover>

		<div class="topbar-profile" v-popover:popover1>
			<div class="topbar-profile__avatar">
				<span class="avatar">
					<img :src="$store.state.partner.avatar_url" alt="">
				</span>
				<div class="check-icon" v-if="$store.state.partner.affiliate_approved"></div>
			</div>
			<div class="topbar-profile__detail">
				<p v-if="$store.state.partner.business_name != null" class="store-name">{{ $store.state.partner.business_name }}</p>
				<p v-else class="store-name">{{ $store.state.partner.first_name }} {{ $store.state.partner.last_name }}</p>
				<p class="user-name">{{ $store.state.partner.email }}</p>
			</div>
			 <svg class="svg-icon" v-bind:class="{ 'rotate-180': profile_dropdown }"><use xlink:href="#icon-outline-arrowdown" ></use></svg>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.icon-button-label.disabled{
	background-color: #F5F5F7;
	margin-top: -0.7rem;
	margin-bottom: -0.7rem;
	padding-bottom: 1.4rem;
	padding-top: 1.4rem;
}
.popover-actions{
	.action-list{
		min-width: 20rem;
		overflow: initial;
		max-height: none;
	}
}
</style>
<script>
export default {
	data: () => ({
    	profile_dropdown: false
 	}),

	methods: {
		logout() {
			axios
			.post("_api/auth/logout")
			.then(response => {
				if (response.data.success) {
					window.Intercom("shutdown");
					this.$router.push("login");
				}
			})
			.catch(error => {
				this.error_message = error.response.data.error;
			})
			.then(() => {});
		}
	}
}
</script>
