<template>
	<div class="page-frame">
		
		

		<sidebar v-if="!blank"></sidebar>
		<topbar v-if="!blank"></topbar>

		<main class="page-frame__main" :class="{ 'page-frame--full' : blank }">

			<slot></slot>

		</main>
	</div>
</template>

<script>

export default {
	props: {
		blank: Boolean
	},

	watch: {
		blank: {
			handler(o) {
				if (o) {
					this.$store.commit("public");
				}
			},
			immediate: true
		}
	},
	created(){

	}

};
</script>
