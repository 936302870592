var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("header", { staticClass: "page-header-wrapper" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "page-header__main" }, [
          _c("h1", { staticClass: "page-header__title" }, [
            _vm._v(_vm._s(_vm.$tt("apps.title")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-header__action" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary",
                attrs: { to: { name: "createApp" } },
                nativeOn: {
                  click: function($event) {
                    return _vm.$tracking.custom_event("create", "app")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$tt("apps.create_app")))]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    !_vm.loading
      ? _c("section", [
          _c("nav", { staticClass: "page-nav-wrapper" }, [
            _c("ul", { staticClass: "page-nav" }, [
              _c("li", { staticClass: "page-nav__item" }, [
                _c(
                  "a",
                  {
                    class: {
                      "page-nav__link": true,
                      active: _vm.type == "draft"
                    },
                    on: {
                      click: function($event) {
                        _vm.type = "draft"
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.$tt("apps.tab_1_title")) +
                        " (" +
                        _vm._s(_vm.apps == null ? "0" : _vm.apps.draft.length) +
                        ")\n\t\t\t\t\t"
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "page-nav__item" }, [
                _c(
                  "a",
                  {
                    class: {
                      "page-nav__link": true,
                      active: _vm.type == "pending"
                    },
                    on: {
                      click: function($event) {
                        _vm.type = "pending"
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$tt("apps.tab_2_title")) +
                        " (" +
                        _vm._s(
                          _vm.apps == null ? "0" : _vm.apps.pending.length
                        ) +
                        ")\n\t\t\t\t\t"
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "page-nav__item" }, [
                _c(
                  "a",
                  {
                    class: {
                      "page-nav__link": true,
                      active: _vm.type == "published"
                    },
                    on: {
                      click: function($event) {
                        _vm.type = "published"
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$tt("apps.tab_3_title")) +
                        " (" +
                        _vm._s(
                          _vm.apps == null ? "0" : _vm.apps.published.length
                        ) +
                        ")\n\t\t\t\t\t"
                    )
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.apps != null
            ? _c("div", { staticClass: "page-layout" }, [
                _c("div", { staticClass: "layout" }, [
                  _c("section", { staticClass: "layout__section" }, [
                    _c("table", { staticClass: "table-list" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$tt("apps.list_name")))]),
                          _vm._v(" "),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.$tt("apps.current_installation")))
                          ]),
                          _vm._v(" "),
                          _vm.type != "draft"
                            ? _c("th", [_vm._v(_vm._s(_vm.$tt("apps.status")))])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.selected_list.length > 0
                        ? _c(
                            "tbody",
                            _vm._l(_vm.selected_list, function(app) {
                              return _c("tr", { key: app.id }, [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "editApp",
                                            params: { app_id: app.id }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(app.name))]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(app.installation_count))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  app.is_approved == 2
                                    ? _c(
                                        "span",
                                        { staticClass: "badge rejected" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$tt("apps.rejected_status")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    : app.is_approved == 3
                                    ? _c(
                                        "span",
                                        { staticClass: "badge pending" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$tt(
                                                  "apps.under_review_status"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    : app.is_approved == 1 && app.is_published
                                    ? _c(
                                        "span",
                                        { staticClass: "badge published" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$tt("apps.published_status")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    : app.is_approved == 1 && !app.is_published
                                    ? _c(
                                        "span",
                                        { staticClass: "badge unpublished" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$tt(
                                                  "apps.unpublished_status"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ])
                            }),
                            0
                          )
                        : _c("tbody", [
                            _c("tr", [
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(
                                  _vm._s(_vm.$tt("apps.empty_row_message"))
                                )
                              ])
                            ])
                          ])
                    ])
                  ])
                ])
              ])
            : _c("div", { staticClass: "page-layout page-empty" }, [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-app" } })
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "page-empty__header" }, [
                  _vm._v(_vm._s(_vm.$tt("apps.empty_app_message")))
                ])
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "page-layout text-center" }, [
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _c("svg", { staticClass: "svg-icon" }, [
                _c("use", { attrs: { "xlink:href": "#icon-info" } })
              ]),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.$tt("apps.footer_documentation"))
                }
              })
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }