<template>
	
	<layout>

		<form :class="{ 'form-loading': form_loading }" @submit.prevent="update">

			<div class="topbar-action">
				<router-link :to="{ name: 'apps' }" class="btn">{{ $tt('common.cancel_button') }}</router-link>
				<button type="submit" class="btn btn-primary">{{ $tt('common.save_button') }}</button>
			</div>

			<header class="page-header-wrapper">
				<div class="page-header">
					<div class="page-header__main">
						<h1 class="page-header__title">{{ $tt('apps.title') }} / {{ this.app.id == null ? $tt('apps.create_app') : this.app.name }}</h1>

						<div class="page-header__action app-header__action">
							<div class="action-link">
								<a v-if="app.id != null" @click.native="$tracking.custom_event('view', 'app')" :href="app_url" target="blank">
									<svg class="svg-icon"><use xlink:href="#icon-new-tab"></use></svg>
									<span>{{ $tt('apps.view_app') }}</span>
								</a> 
							</div>
							<div v-if="app.id != null" class="action-link">
								<delete-button
							
								:text="$tt('apps.delete_app')"
								:icon="'trash'"
								:form_loading="form_loading"
								:api="{
									url: '/_api/apps/'+app.id,
									redirect: { path: '/apps', query: null }
								}"
								:dialog="{
									title: $tt('apps.delete_dialog_title', {value1: app.name }),
									description: $tt('apps.delete_dialog_description'),
									button: {
										cancel: $tt('common.cancel_button'),
										confirm: $tt('apps.delete_button'),
									},
									message: {
										success: $tt('apps.delete_dialog_success_msg'),
										error: $tt('apps.delete_dialog_error_msg'),
									},
								}"
								></delete-button>
							</div>
							<div v-if="app.id != null && app.is_approved == 1" class="action-link">

								<publish-button

								:text="app.is_published ? $tt('apps.unpublish_app') : $tt('apps.publish_app')"
								:icon="app.is_published ? 'cancel' : 'confirm'"
								:form_loading="form_loading"
								:api="{
									url: app.is_published ? '/_api/apps/'+app.id+'/unpublish' : '/_api/apps/'+app.id+'/publish',
									redirect: { path: '/apps/'+app.id, query: null }
								}"
								:dialog="{
									title: app.is_published ? $tt('apps.unpublish_dialog_title', {value1: app.name }) : $tt('apps.publish_dialog_title', {value1: app.name }),
									description: app.is_published ? $tt('apps.unpublish_dialog_description') : $tt('apps.publish_dialog_description'),
									button: {
										cancel: $tt('common.cancel_button'),
										confirm: app.is_published ? $tt('apps.unpublish_button') : $tt('apps.publish_button'),
									},
									message: {
										success: app.is_published ? $tt('apps.unpublish_dialog_success_msg') : $tt('apps.publish_dialog_success_msg'),
										error: app.is_published ? $tt('apps.unpublish_dialog_success_msg') : $tt('apps.publish_dialog_error_msg'),
									},
								}"

								></publish-button>
								
							</div>

							<div v-if="app.id != null && (app.is_approved == 2 || app.is_approved == 0)" class="action-link">


								<review-button

								:text="$tt('apps.review_app')"
								:form_loading="form_loading"
								:api="{
									url: '/_api/apps/'+app.id+'/submit',
									redirect: { path: '/apps', query: null }
								}"
								:dialog="{
									title: $tt('apps.review_dialog_title', {value1: app.name }),
									description: $tt('apps.review_dialog_description'),
									button: {
										cancel: $tt('common.cancel_button'),
										confirm: $tt('apps.review_button'),
									},
									message: {
										success: $tt('apps.review_dialog_success_msg'),
										error: $tt('apps.review_dialog_error_msg'),
									},
								}"								

								></review-button>

							</div>

						</div>

					</div>
				</div>
			</header>

			<div class="page-layout home-layout">

				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('apps.app.title') }}</h3>
								<p>{{ $tt('apps.app.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">

						<div class="card">

							<div class="card__section">

								<div class="input-wrapper">
									<label class="input-label" for="name">{{ $tt('apps.app.name') }}</label>
									<input 
									:class="{ error:errors.has('name'), 'input-control': true }"
									type="text" 
									name="name" 
									v-model.trim="app.name"
									v-validate="'required'"
									data-vv-validate-on="none"		
									:data-vv-as="$tt('apps.app.name')"	
									>

									<span v-if="errors.has('name')" class="error-block">{{ errors.first('name') }}</span>
								</div>								

								<div class="input-wrapper">
									<label class="input-label" for="short_description">{{ $tt('apps.app.short_description') }}</label>
									<textarea 
									:class="{ error:errors.has('short_description'), 'input-control': true }"
									name="short_description" 
									v-model.trim="app.short_description"
									v-validate="'max:120'"
									data-vv-delay="550"
									:data-vv-as="$tt('apps.app.short_description')"
									></textarea>

									<span v-if="!errors.has('short_description')" class="help-block text-right">{{ short_description_remaining }}</span>

									<span v-if="errors.has('short_description')" class="error-block">{{ errors.first('short_description') }}</span>

								</div>	

								<div class="input-wrapper">

									<label class="input-label" for="">{{ $tt('apps.app.app_description') }}</label>

									<editor v-model="app.description" :placeholder="$tt('apps.app.description_placeholder')" ></editor>

								</div>	


								<div class="input-wrapper">
									<label class="input-label" for="support_url">{{ $tt('apps.url.support_url') }}</label>
									<input 
									:class="{ error:errors.has('support_url'), 'input-control': true }"
									type="text" 
									name="support_url" 
									v-model.trim="app.support_url"
									v-validate="{ url: { require_protocol: true } }"
									data-vv-validate-on="none"
									:data-vv-as="$tt('apps.url.support_url')"
									:placeholder="$tt('apps.url.support_url_placeholder')"
									>

									<span v-if="errors.has('support_url')" class="error-block">{{ errors.first('support_url') }}</span>
									<span class="help-block">{{ $tt('apps.url.support_url_description') }}</span>
								</div>	
						

							</div>

						</div>

					</section>

				</div>

				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('apps.url.title') }}</h3>
								<p>{{ $tt('apps.url.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">

						<div class="card">

							<div class="card__section">

								<div class="input-wrapper">
									<label class="input-label" for="application_url">{{ $tt('apps.url.application_url') }}</label>
									<input 
									:class="{ error:errors.has('application_url'), 'input-control': true }"
									type="text" 
									name="application_url" 
									v-model.trim="app.application_url"
									v-validate="{ required: true, url: { require_protocol: true } }"
									data-vv-validate-on="none"
									:data-vv-as="$tt('apps.url.application_url')"
									:placeholder="$tt('apps.url.application_url_placeholder')"
									>
									<span v-if="errors.has('application_url')" class="error-block">{{ errors.first('application_url') }}</span>
									<span class="help-block">
										{{ $tt('apps.url.application_url_description') }}
									</span>
								</div>	

								<div class="input-wrapper">
									<label class="input-label" for="fulfillment_url">{{ $tt('apps.url.fulfillment_url') }}</label>
									<input 
									:class="{ error:errors.has('fulfillment_url'), 'input-control': true }"
									type="text" 
									name="fulfillment_url" 
									v-model.trim="app.fulfillment_url"
									v-validate="{ url: { require_protocol: true } }"
									data-vv-validate-on="none"
									:data-vv-as="$tt('apps.url.fulfillment_url')"
									>

									<span v-if="errors.has('fulfillment_url')" class="error-block">{{ errors.first('fulfillment_url') }}</span>
								</div>	

								<div class="input-wrapper">
									<label class="input-label" for="redirection_url">{{ $tt('apps.url.redirection_url') }}</label>
									<textarea 
									class="input-control" 
									name="redirection_url" 
									v-model.trim="app.redirection_url"
									v-validate="'required'"
									data-vv-validate-on="none"
									:data-vv-as="$tt('apps.url.redirection_url')"
									:placeholder="$tt('apps.url.redirection_url_placeholder')"
									></textarea>

									<span v-if="errors.has('redirection_url')" class="error-block">{{ errors.first('redirection_url') }}</span>
									<span class="help-block">{{ $tt('apps.url.redirection_url_description') }}</span>
								</div>	

							</div>

						</div>

					</section>

				</div>

				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('apps.proxy.title') }}</h3>
								<p>{{ $tt('apps.proxy.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">

						<div class="card">

							<div class="card__section">

								<div class="input-wrapper">
									<label class="input-label" for="proxy_url">{{ $tt('apps.proxy.proxy_url') }}</label>
									<input 
									:class="{ error:errors.has('proxy_url'), 'input-control': true }"
									type="text" 
									name="proxy_url" 
									:placeholder="$tt('apps.proxy.proxy_url_placeholder')" 
									v-model.trim="app.proxy_url"
									v-validate="{ url: { require_protocol: true }, required: ( (app.proxy_prefix != null && app.proxy_prefix.length > 0) || (app.proxy_sub_path != null && app.proxy_sub_path.length > 0) ) }"
									:data-vv-as="$tt('apps.proxy.proxy_url')"
									>
									<span v-if="errors.has('proxy_url')" class="error-block">{{ errors.first('proxy_url') }}</span>
								</div>

								<div class="input-wrapper">
									<label for="" class="input-label">{{ $tt('apps.proxy.proxy_prefix') }}</label>
									
									<select
									class="input-control"
									:class="{ error: errors.has('proxy_prefix') }" 
									name="proxy_prefix"
									clearable 
									placeholder="Select" 
									v-model.trim="app.proxy_prefix"
									v-validate="{ required: ( (app.proxy_url != null && app.proxy_url.length > 0) || (app.proxy_sub_path != null && app.proxy_sub_path.length > 0) ) }"
									:data-vv-as="$tt('apps.proxy.proxy_prefix')"
									>
										<option :value="null" disabled selected label="Select">Select</option>
										<option value="a" label="a">a</option>
										<option value="apps" label="apps">apps</option>
										<option value="community" label="community">community</option>
										<option value="tools" label="tools">tools</option>
									</select>	

									<!-- <el-select
									:class="{ error: errors.has('proxy_prefix') }" 
									name="proxy_prefix"
									clearable 
									placeholder="Select" 
									v-model.trim="app.proxy_prefix"
									v-validate="{ required: ( (app.proxy_url != null && app.proxy_url.length > 0) || (app.proxy_sub_path != null && app.proxy_sub_path.length > 0) ) }"
									:data-vv-as="$tt('apps.proxy.proxy_prefix')"
									>
										<el-option value="a" label="a"></el-option>
										<el-option value="apps" label="apps"></el-option>
										<el-option value="community" label="community"></el-option>
										<el-option value="tools" label="tools"></el-option>
									</el-select>	 -->

									<span v-if="errors.has('proxy_prefix')" class="error-block">{{ errors.first('proxy_prefix') }}</span>			
								</div>										

								<div class="input-wrapper">
									<label class="input-label" for="proxy_sub_path">{{ $tt('apps.proxy.sub_path') }}</label>
									<input 
									:class="{ error:errors.has('proxy_sub_path'), 'input-control': true }"
									type="text" 
									name="proxy_sub_path" 
									:placeholder="$tt('apps.proxy.sub_path_placeholder')" 
									v-model.trim="app.proxy_sub_path"
									v-validate="{ required: ( (app.proxy_url != null && app.proxy_url.length > 0) || (app.proxy_prefix != null && app.proxy_prefix.length > 0) ) }"
									:data-vv-as="$tt('apps.proxy.sub_path')"
									>
									<span v-if="errors.has('proxy_sub_path')" class="error-block">{{ errors.first('proxy_sub_path') }}</span>	
								</div>

							</div>

						</div>

					</section>

				</div>

				<div class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('apps.icon.title') }}</h3>
								<p>{{ $tt('apps.icon.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">

						<div class="card">

							<div class="card__section">

								<div class="input-wrapper">
									<label class="input-label">{{ $tt('apps.icon.icon') }}</label>
									<div class="form-group form-group--spaced upload-wrapper">
										<div class="app-icon__wrapper">
											<img :src="app.img_icon_url" alt="icon">
										</div>
										
										<div class="upload-cta">

											<upload-button
											:apiUrl="'/_api/images/upload?type=app_icon'"
											:mbLimit="'10'"
											:accept="'image/png, image/gif, image/jpeg'"
											:form_loading.sync="form_loading"
											:image_url.sync="app.img_icon_url"
											></upload-button>

										</div>
									</div>
									<span class="help-block">
										{{ $tt('apps.icon.icon_description') }}
									</span>
								</div>

								<div class="input-wrapper">
									<label class="input-label">{{ $tt('apps.icon.banner') }}</label>

									<div class="form-group form-group--spaced upload-wrapper">
										<div class="app-banner__wrapper">
											<img :src="app.img_banner_url" alt="banner">
										</div>
										<div class="upload-cta">

											<upload-button
											:apiUrl="'/_api/images/upload?type=app_banner'"
											:mbLimit="'10'"
											:accept="'image/png, image/gif, image/jpeg'"
											:form_loading.sync="form_loading"
											:image_url.sync="app.img_banner_url"
											></upload-button>

										</div>
									</div>
									<span class="help-block">
										{{ $tt('apps.icon.banner_description') }}
									</span>
								</div>

								<div class="input-wrapper">
									<label class="input-label">{{ $tt('apps.icon.embed') }}</label>
									
									<div>
										<input type="radio" v-model="app.is_embedded" :value="false" id="disable">
										<span class="input-checkmark"></span>
										<label for="disable">{{ $tt('apps.icon.disable_button') }}</label>
										
										<input type="radio" v-model="app.is_embedded" :value="true" id="enable">
										<span class="input-checkmark"></span>
										<label for="enable">{{ $tt('apps.icon.enable_button') }}</label>
									</div>

								</div>


							</div>
						</div>

					</section>

				</div>

				<div v-if="app.app_id != null" class="layout layout--2">

					<section class="layout__section">
						<div class="card card--transparent">
							<div class="card__section">
								<h3>{{ $tt('apps.credential.title') }}</h3>
								<p>{{ $tt('apps.credential.description') }}</p>
							</div>
						</div>
					</section>

					<section class="layout__section">

						<div class="card">

							<div class="card__section">

								<div class="input-wrapper">
									<label class="input-label" for="client_id">{{ $tt('apps.credential.client_id') }}</label>
									<input class="input-control" readonly="" ref="copy_id" type="text" name="client_id" v-model="app.app_id">
								</div>	

								<div class="input-wrapper">
									<label class="input-label" for="client_secret">{{ $tt('apps.credential.client_secret') }}</label>
									<div class="input-control-group show-secret-field">
										<input class="input-control" readonly="" :type="client_secret_field" name="client_secret" v-model="app.app_secret">

										<a class="btn btn-link" @click="hide_secret = !hide_secret">
											<svg class="svg-icon"><use v-bind="{'xlink:href': hide_secret ? '#icon-eye' : '#icon-eye-slash' }" ></use></svg> 
										</a>
									</div>

								</div>	

							</div>

						</div>

					</section>

				</div>

			</div>


		</form>

	</layout>

</template>
<style lang="scss">
.app-header__action {
  margin-left: -6px;
}
</style>
<script>
export default {
  data() {
    return {
      app: {
        id: null,
        name: "",
        short_description: "",
        description: "",
        application_url: null,
        support_url: null,
        redirection_url: null,
        proxy_url: null,
        proxy_prefix: null,
        proxy_sub_path: null,
        is_embedded: false,
        img_banner_url:
          "https://s3-ap-southeast-1.amazonaws.com/eesb.public/images/apps/apps_default.png",
        img_icon_url:
          "https://s3-ap-southeast-1.amazonaws.com/eesb.public/images/apps/apps_default-sq.png"
      },

      max_char: 120,
      hide_secret: true,
      form_loading: false
    };
  },

  computed: {
    client_secret_field() {
      if (!this.hide_secret) {
        return "text";
      } else {
        return "password";
      }
    },

    app_url() {
      return process.env.MIX_WEBSITE_DOMAIN + "/apps/" + this.app.handle;
    },

    short_description_remaining() {
      var remaining = this.max_char;

      if (
        this.app.short_description != null &&
        this.app.short_description.length > 0
      ) {
        remaining = this.max_char - this.app.short_description.length;
      }

      remaining = remaining < 0 ? 0 : remaining;

      return this.$tt("apps.app.remaining_char", { value1: remaining });
    }
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },

    update() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;

          if (this.app.id != null) {
            var url = "/_api/apps/" + this.app.id;
          } else {
            var url = "/_api/apps";
          }

          axios
            .post(url, this.app)
            .then(response => {
              if (this.app.id != null) {
                Object.assign(this.app, response.data.app);

                this.$tracking.custom_event("edited", "app");

                this.toast("success", this.$tt("apps.update_success_message"));

                window.location.reload()

              } else {
                this.toast("success", this.$tt("apps.create_success_message"));

                this.$tracking.custom_event("created", "app");

                this.$router.push("/apps/" + response.data.app.id);
              }
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    },
    retrieve() {
      axios
        .get("/_api/apps/" + this.$route.params.app_id)
        .then(response => {
          this.app = response.data.app;

          this.$tracking.custom_event("edit", "app");
        })
        .catch(error => {
          this.$router.push("/apps");
        })
        .then(() => {});
    }
  },
  created() {
    if (typeof this.$route.params.app_id !== "undefined") {
      this.retrieve();
    }
  }
};
</script>
