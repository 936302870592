var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("header", { staticClass: "page-header-wrapper" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "page-header__main" }, [
          _c("h1", { staticClass: "page-header__title" }, [
            _vm._v("Welcome to UI Lab")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page-header__action" }, [
            _c("div", { staticClass: "action-link" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-plus" } })
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("Add")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "action-link" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-edit" } })
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("Edit")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "action-link" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-trash" } })
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("Delete")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-header__action" }, [
          _vm._v("\n\t\t\t\t-----\n\t\t\t")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "layout" }, [
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card card--transparent" }, [
            _c("header", { staticClass: "card__header" }, [
              _c("h2", [_vm._v("Table")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card__section" }, [
              _c("table", { staticClass: "table-list" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("App name")]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("Current installation")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(6, function(index) {
                    return _c("tr", [
                      _c("td", [
                        _c("a", { attrs: { href: "" } }, [_vm._v("My app")])
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-center" }, [_vm._v("1")])
                    ])
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layout" }, [
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card" }, [
            _c("header", { staticClass: "card__header" }, [
              _c("h2", [_vm._v("Froala Editor")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card__section" }, [_c("editor")], 1)
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layout" }, [
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card" }, [
            _c("header", { staticClass: "card__header" }, [
              _c("h2", [_vm._v("Welcome to EasyStore Icon SVG")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card__section" }, [
              _c("div", { staticClass: "input-wrapper" }, [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v("Use case")
                ]),
                _vm._v(" "),
                _c(
                  "textarea",
                  { staticClass: "input-control", attrs: { readonly: "" } },
                  [
                    _vm._v(
                      ' <svg class="svg-icon"><use xlink:href="#icon-name" ></use></svg> '
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "icon-grid" },
                _vm._l(_vm.icons, function(icon) {
                  return _c(
                    "div",
                    { staticClass: "icon-cell ui-lab-svg-container" },
                    [
                      _c("svg", { staticClass: "ui-lab-svg-icon" }, [
                        _c(
                          "use",
                          _vm._b(
                            {},
                            "use",
                            { "xlink:href": "#icon-" + icon },
                            false
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("icon-" + _vm._s(icon))])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }