	<template>

	<aside class="page-frame__aside" :class="{ 'active': menuIsOpen}">
		
		<div class="mobile-nav"  @click="toggleMenu">
			<div class="burger">
				<span></span><span></span><span></span>
			</div>
		</div>


		<nav class="nav-sidebar" >

			<div class="sidebar-avatar">
				<account-menu></account-menu>
				<!-- <div class="topbar-profile">
					<div class="topbar-profile__avatar">
						<span class="avatar">
							<img :src="$store.state.partner.avatar_url" alt="">
						</span>
					</div>
					<div class="topbar-profile__detail">
						<p v-if="$store.state.partner.business_name != null" class="store-name">{{ $store.state.partner.first_name }} {{ $store.state.partner.last_name }}</p>
						<p v-else class="store-name">{{ $store.state.partner.first_name }} {{ $store.state.partner.last_name }}</p>
						<p class="user-name">{{ $store.state.partner.email }}</p>
					</div>
				</div> -->
			</div>
			<ul class="nav-sidebar__group">

				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'home' }">
						<svg class="sidebar-icon"><use xlink:href="#icon-general"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.home') }}</span>
					</router-link>
				</li>

				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'stores' }">
						<svg class="sidebar-icon"><use xlink:href="#icon-store"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.stores') }}</span>
					</router-link>
				</li>

				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'affiliates' }">
						<!-- <img src="/images/partners/affiliates.svg" alt=""> -->
						<!-- <svg class="sidebar-icon"><use xlink:href="#icon-marketing"></use></svg> -->
						<svg class="sidebar-icon"><use xlink:href="#icon-promotion"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.affiliates') }}</span>
					</router-link>

					<!-- <ul class="nav-sidebar__sub-group">
						<li>
							<router-link 
							class="nav-sidebar__sub-link"
							:to="{ name: 'withdrawal' }"
							>Withdraw commssion</router-link>
						</li>
					</ul> -->
				</li>

				<!-- <li>
					<router-link class="nav-sidebar__link" :to="{ name: 'contents' }">
						<svg class="sidebar-icon"><use xlink:href="#icon-page"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.articles') }}</span>
					</router-link>
				</li> -->
			
				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'apps' }">
						<!-- <img src="/images/partners/app.svg" alt=""> -->
						<svg class="sidebar-icon"><use xlink:href="#icon-app"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.apps') }}</span>
					</router-link>
				</li>

				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'payments' }">
						<!-- <img src="/images/partners/app.svg" alt=""> -->
						<svg class="sidebar-icon"><use xlink:href="#icon-app"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.payments') }}</span>
					</router-link>
				</li>

				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'themes' }">
						<svg class="sidebar-icon"><use xlink:href="#icon-theme"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.themes') }}</span>
					</router-link>
				</li>
				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'settings' }">
						<svg class="sidebar-icon"><use xlink:href="#icon-setting"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.settings') }}</span>
					</router-link>
				</li>

			</ul>
			<!-- <ul class="nav-sidebar__group">
				<li>
					<router-link class="nav-sidebar__link" :to="{ name: 'settings' }">
						<svg class="sidebar-icon"><use xlink:href="#icon-setting"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.settings') }}</span>
					</router-link>
				</li>
				<li>
					<a class="nav-sidebar__link" @click="logout">
						<svg class="sidebar-icon"><use xlink:href="#icon-logout"></use></svg>
						<span class="sidebar-label">{{ $tt('sidebar.logout') }}</span>
					</a>
				</li>

			</ul> -->
			
		</nav>
	</aside>

</template>

<script>
export default {
  data() {
    return {
      menuIsOpen: false
    };
  },
  methods: {
    toggleMenu: function() {
      this.menuIsOpen = !this.menuIsOpen;
      if (this.menuIsOpen == true) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },

    logout() {
      axios
        .post("_api/auth/logout")
        .then(response => {
          if (response.data.success) {
            if (window.$crisp) window.$crisp.push(["do", "session:reset"]);
            this.$router.push("login");
          }
        })
        .catch(error => {
          this.error_message = error.response.data.error;
        })
        .then(() => {});
    }
  }
};
</script>
