import { render, staticRenderFns } from "./payments.vue?vue&type=template&id=d3015f36&"
import script from "./payments.vue?vue&type=script&lang=js&"
export * from "./payments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/partner/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('d3015f36', component.options)
    } else {
      api.reload('d3015f36', component.options)
    }
    module.hot.accept("./payments.vue?vue&type=template&id=d3015f36&", function () {
      api.rerender('d3015f36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/partner/views/payments/payments.vue"
export default component.exports