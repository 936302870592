<template>
 
  <div>

  <el-select v-if="provinces.length >= 1" filterable default-first-option v-model="int_province_code" :placeholder="placeholder">

    <el-option v-for="p in provinces" :value="p.code" :key="p.code" :label="p.name"></el-option>

  </el-select>

  <input v-else type="text" :class="['input-control', {'error': errors.has('int_province_name') }]" v-model="int_province_name" data-vv-id="int_province_name" data-vv-name="int_province_name" :data-vv-as="placeholder" :placeholder="placeholder + (required_placeholder && required_placeholder.includes('province') ? '*' : '')">
  <span v-show="errors.has('int_province_name')" class="error-block">{{ errors.first('int_province_name') }}</span>

  </div>


</template>

<script>
module.exports = {
  props: ["value", "province_name", "province_code", "required_placeholder"],
  data: () => ({
    loading: true,
    provinces: [],
    int_province_name: "",
    int_province_code: ""
  }),
  computed: {
    placeholder: function() {
      return "";

      // if (["IN", "BR", "CL", "MX", "NG", "US", "UM"].includes(this.value))
      //   return this.$tt("address.state");
      // else if (["EG"].includes(this.value)) return this.$tt("address.governorate");
      // else if (["AU", "MY"].includes(this.value)) return this.$tt("address.state_territory");
      // else if (["IE", "RO"].includes(this.value)) return this.$tt("address.country");
      // else if (["JP"].includes(this.value)) return this.$tt("address.prefecture");
      // else if (["AE"].includes(this.value)) return this.$tt("address.emirate");
      // else if (
      //   ["DZ", "AR", "BY", "CA", "CN", "CO", "ID", "IT", "LA", "ZA", "KR", "ES", "UZ"].includes(
      //     this.value
      //   )
      // )

      //   return this.$tt("address.province");
      // else if (["TW"].includes(this.value)) return this.$tt("address.province_tw");
      // else return this.$tt("address.region");
    }
  },
  watch: {
    int_province_name: function(obj) {
      this.$emit("update:province_name", this.int_province_name);
      this.$emit("update:province_code", this.int_province_code);
    },
    int_province_code: function(obj) {
      for (var i = 0; i < this.provinces.length; i++) {
        if (this.int_province_code == this.provinces[i].code) {
          this.$emit("update:province_name", this.provinces[i].name);
          this.$emit("update:province_code", this.provinces[i].code);
        }
      }
    },
    value: function() {
      this.reset();
    },
    province_code: function() {
      this.int_province_code = this.province_code;
    },
    province_name: function() {
      this.province_name = this.province_name;
    }
  },
  methods: {
    reset: function() {
      this.int_province_name = this.province_name;
      this.int_province_code = this.province_code;

      axios
        .get("/_api/references/provinces", {
          params: {
            country_code: this.value
          }
        })
        .then(response => {
          this.provinces = response.data.provinces;

          if (this.provinces && this.provinces.length >= 1) {
            // this.int_province_name = ''

            var cached_province_code = this.int_province_code;

            this.int_province_code = "";
            for (var i = 0; i < this.provinces.length; i++) {
              if (cached_province_code == this.provinces[i].code) {
                this.int_province_code = this.provinces[i].code;
                this.int_province_name = this.provinces[i].name;
              }
            }

            if (this.int_province_code == "") {
              this.int_province_code = this.provinces[0].code;
              this.int_province_name = this.provinces[0].name;
            }
          } else if (
            (this.int_province_code == "" || this.int_province_code == null) &&
            (this.int_province_name != "" || this.int_province_name != null)
          ) {
            this.int_province_code = "";
            this.int_province_name = this.int_province_name;
          } else {
            this.int_province_code = "";
            this.int_province_name = "";
          }
        })
        .catch(e => {
          this.error.push(e);
        });
    }
  },
  mounted() {
    this.reset();
  }
};
</script>
