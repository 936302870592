<template>
  <layout blank class="site-auth">
    <div class="page-layout">
      <div class="card">
        <div class="card__section">
          <div class="site-auth__logo">
            <a href="/">
              <img src="/images/partners/partners-logo.svg" alt="logo">
            </a>
          </div>
          <hr>

          <h1 class="site-auth__title">{{ $tt('auth.login.title') }}</h1>

          <div class="input-wrapper input-wrapper--small">
            <span id="paypal_login" @click.prevent></span>
          </div>
          <hr>
          <form :class="{ 'form-loading': form_loading }" @submit.prevent="login">
            <div class="input-wrapper input-wrapper--small">
              <input
                :class="{ error:errors.has('email'), 'input-control': true }"
                type="email"
                name="email"
                :placeholder="$tt('auth.login.placeholder.email')"
                v-model.trim="auth.email"
                @input="auth.email = $event.target.value.toLowerCase()"
                v-validate="'required|email'"
                data-vv-validate-on="none"
                :data-vv-as="$tt('auth.login.placeholder.email')"
              >

              <span v-if="errors.has('email')" class="error-block">{{ errors.first('email') }}</span>
            </div>
            <div class="input-wrapper input-wrapper--small">
              <input
                :class="{ error:errors.has('password'), 'input-control': true }"
                type="password"
                name="password"
                :placeholder="$tt('auth.login.placeholder.password')"
                v-model.trim="auth.password"
                v-validate="'required'"
                data-vv-validate-on="none"
                :data-vv-as="$tt('auth.login.placeholder.password')"
              >

              <span
                v-show="errors.has('password')"
                class="error-block"
              >{{ errors.first('password') }}</span>
            </div>

            <div class="form-group form-group--spaced">
              <div class="input-wrapper input-wrapper--small">
                <input
                  type="checkbox"
                  name="is_remember"
                  id="is_remember"
                  v-model="auth.is_remember"
                >
                <span class="input-checkmark"></span>
                <label for="is_remember">{{ $tt('auth.login.remember_me') }}</label>
              </div>
              <div class="input-wrapper input-wrapper--small">
                <router-link
                  :to="{ name: 'forgot-password' }"
                >{{ $tt('auth.login.forgot_password_link') }}</router-link>
              </div>
            </div>

            <div class="input-wrapper input-wrapper--small">
              <button type="submit" class="btn btn-primary">{{ $tt('auth.login.submit_button') }}</button>
            </div>

            <div class="input-wrapper input-wrapper--small">
              {{ $tt('auth.login.create_account_part_1') }}
              <router-link :to="{ name: 'signup' }">{{ $tt('auth.login.create_account_link') }}</router-link>
            </div>
            <hr>

            <p class="terms">
              <small>
                By signing up EasyStore Partner Program, you agree to our
                <a
                  href="https://support.easystore.co/en/article/easystore-partners-terms-conditions-1mnybnt/"
                >Terms & Condition</a>
              </small>
            </p>
          </form>
        </div>
      </div>
    </div>
  </layout>
</template>
<style lang="scss">
.terms {
  line-height: 1.5;
  // color: #cacaca;
  a {
  }
}
</style>

<script>
export default {
  data() {
    return {
      auth: {
        email: null,
        password: null,
        is_remember: true
      },

      form_loading: false
    };
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },
    paypal_load() {
      (function(a, b, c, d, e) {
        p = b.querySelectorAll("script[src='" + d + "']")[0];

        function run() {
          if (process.env.NODE_ENV != "production") {
            e = {
              appid: process.env.MIX_PAYPAL_APP_ID,
              authend: process.env.MIX_PAYPAL_ENV,
              scopes: "openid profile email address phone",
              containerid: "paypal_login",
              locale: "en-us",
              returnurl: process.env.MIX_PAYPAL_RETURN_URL
            };
          } else {
            e = {
              appid: process.env.MIX_PAYPAL_APP_ID,
              scopes: "openid profile email address phone",
              containerid: "paypal_login",
              locale: "en-us",
              returnurl: process.env.MIX_PAYPAL_RETURN_URL
            };
          }

          paypal.use(["login"], login => {
            login.render(e);
          });
        }

        if (typeof p == "undefined") {
          var p = b.createElement(c);
          p.src = d;
          var s = b.getElementsByTagName("body")[0];
          p.onload = () => {
            run();
          };
          s.appendChild(p);
        } else {
          run();
        }
      })(
        window,
        document,
        "script",
        "https://www.paypalobjects.com/js/external/api.js"
      );
    },

    login() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;

          axios
            .post("_api/auth/login", this.auth)
            .then(response => {
              if (response.data.success) {
                this.$store.commit("boot");
                this.$tracking.login("direct");
                if (this.$route.query.redirect_url) {
                  this.$router.push(this.$route.query.redirect_url);
                } else {
                  this.$router.push("/");
                }
              }
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    }
  },

  created() {
    this.paypal_load();
  }
};
</script>
