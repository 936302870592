var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn btn-link-danger",
      class: _vm.classes,
      on: {
        click: function($event) {
          return _vm.ask()
        }
      }
    },
    [
      _vm.icon == "trash"
        ? _c("svg", { staticClass: "svg-icon" }, [
            _c("use", {
              attrs: {
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                "xlink:href": "#icon-trash"
              }
            })
          ])
        : _vm.icon == "reset"
        ? _c("svg", { staticClass: "svg-icon" }, [
            _c("use", {
              attrs: {
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                "xlink:href": "#icon-reset"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.text))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }