<template>

	<layout>
		
		<header class="page-header-wrapper">
			<div class="page-header">
				<div class="page-header__main">
					<h1 class="page-header__title">{{ $tt('apps.title') }}</h1>
				</div>

				<div class="page-header__action">

					<router-link :to="{ name: 'createApp' }" @click.native="$tracking.custom_event('create', 'app')" class="btn btn-primary">{{ $tt('apps.create_app') }}</router-link>

				</div>
			</div>
		</header>
		
		<section v-if="!loading">
			
			<nav class="page-nav-wrapper">
				<ul class="page-nav">
					<li class="page-nav__item">
						<a 
						@click="type='draft'" 
						:class="{ 'page-nav__link': true, 'active': type == 'draft' }">
							{{ $tt('apps.tab_1_title') }} ({{ apps == null ? '0' : apps.draft.length }})
						</a>
					</li>
					<li class="page-nav__item">
						<a 
						@click="type='pending'" 
						:class="{ 'page-nav__link': true, 'active': type == 'pending' }">
						{{ $tt('apps.tab_2_title') }} ({{ apps == null ? '0' : apps.pending.length }})
						</a>
					</li>
					<li class="page-nav__item">
						<a 
						@click="type='published'" 
						:class="{ 'page-nav__link': true, 'active': type == 'published' }">
						{{ $tt('apps.tab_3_title') }} ({{ apps == null ? '0' : apps.published.length }})
						</a>
					</li>
				</ul>
			</nav>

			<div class="page-layout" v-if="apps != null">

				<div class="layout">

					<section class="layout__section">

						<table class="table-list">
							<thead>
								<tr>
									<th>{{ $tt('apps.list_name') }}</th>
									<th class="text-center">{{ $tt('apps.current_installation') }}</th>
									<th v-if="type !='draft'">{{ $tt('apps.status') }}</th>
								</tr>
							</thead>
							<tbody v-if="selected_list.length > 0">
								<tr v-for="app in selected_list" :key="app.id" >
									<td><router-link :to="{ name: 'editApp', params: { app_id: app.id } }">{{ app.name }}</router-link></td>
									<td class="text-center">{{ app.installation_count }}</td>

									<td>
										<span class="badge rejected" v-if="app.is_approved == 2">
											{{ $tt('apps.rejected_status') }}
										</span>

										<span class="badge pending" v-else-if="app.is_approved == 3" >
											{{ $tt('apps.under_review_status') }}
										</span>

										<span class="badge published" v-else-if="app.is_approved == 1 && app.is_published">
											{{ $tt('apps.published_status') }}
										</span>

										<span class="badge unpublished" v-else-if="app.is_approved == 1 && !app.is_published">
											{{ $tt('apps.unpublished_status') }}
										</span>
									</td>
									
								</tr>
							</tbody>

							<tbody v-else>

								<tr>

									<td colspan="3">{{ $tt('apps.empty_row_message') }}</td>

								</tr>
								
							</tbody>

						</table>

					</section>

				</div>

			</div>
			
			<div v-else class="page-layout page-empty">
				<svg class="svg-icon"><use xlink:href="#icon-app"></use></svg>
				<h2 class="page-empty__header">{{ $tt('apps.empty_app_message') }}</h2>
			</div>

			<div class="page-layout text-center">
				<br>
				<p><svg class="svg-icon"><use xlink:href="#icon-info" ></use></svg><span v-html="$tt('apps.footer_documentation')"></span></p>
			</div>	

		</section>

	</layout>

</template>

<script>
export default {
  data() {
    return {
      apps: null,

      selected_list: null,

      type: "draft",

      loading: true
    };
  },

  watch: {
    type: {
      handler(o) {
        if (this.apps != null) {
          if (o == "pending") {
            this.selected_list = this.apps.pending;
          } else if (o == "published") {
            this.selected_list = this.apps.published;
          } else if (o == "draft") {
            this.selected_list = this.apps.draft;
          }
        }
      }
    }
  },

  methods: {
    list() {
      axios
        .get("_api/apps")
        .then(response => {
          if (response.data.apps) {
            this.apps = response.data.apps;
            this.selected_list = response.data.apps.draft;
          }
        })
        .catch(error => {
          this.error_message = error.response.data.error;
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.list();
  }
};
</script>
