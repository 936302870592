var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          editable: false,
          "popper-class": "daterange",
          "range-separator": " — ",
          "start-placeholder": "Start date",
          "end-placeholder": "End date",
          "picker-options": {
            firstDayOfWeek: 1,
            disabledDate: function(time) {
              return time.getTime() > Date.now()
            }
          }
        },
        model: {
          value: _vm.start_end_array,
          callback: function($$v) {
            _vm.start_end_array = $$v
          },
          expression: "start_end_array"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }