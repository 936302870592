var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    { attrs: { visible: _vm.show }, on: { close: _vm.close } },
    [
      _c(
        "form",
        {
          class: { "form-loading": _vm.form_loading },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.reset($event)
            }
          }
        },
        [
          _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.auth.current_password,
                  expression: "auth.current_password",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:6",
                  expression: "'required|min:6'"
                }
              ],
              class: {
                error: _vm.errors.has("current_password"),
                "input-control": true
              },
              attrs: {
                type: "password",
                name: "current_password",
                placeholder: _vm.$tt(
                  "settings.change_password.placeholder.current_password"
                ),
                "data-vv-validate-on": "none",
                "data-vv-as": _vm.$tt(
                  "settings.change_password.placeholder.current_password"
                )
              },
              domProps: { value: _vm.auth.current_password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.auth,
                    "current_password",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("current_password")
              ? _c("span", { staticClass: "error-block" }, [
                  _vm._v(_vm._s(_vm.errors.first("current_password")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.auth.new_password,
                  expression: "auth.new_password",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:6",
                  expression: "'required|min:6'"
                }
              ],
              class: {
                error: _vm.errors.has("new_password"),
                "input-control": true
              },
              attrs: {
                type: "password",
                name: "new_password",
                placeholder: _vm.$tt(
                  "settings.change_password.placeholder.new_password"
                ),
                "data-vv-validate-on": "none",
                "data-vv-as": _vm.$tt(
                  "settings.change_password.placeholder.new_password"
                )
              },
              domProps: { value: _vm.auth.new_password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.auth, "new_password", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("new_password")
              ? _c("span", { staticClass: "error-block" }, [
                  _vm._v(_vm._s(_vm.errors.first("new_password")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.auth.new_password_confirmation,
                  expression: "auth.new_password_confirmation",
                  modifiers: { trim: true }
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    min: 6,
                    confirmed: _vm.auth.new_password
                  },
                  expression:
                    "{ required: true, min: 6, confirmed: auth.new_password }"
                }
              ],
              class: {
                error: _vm.errors.has("new_password_confirmation"),
                "input-control": true
              },
              attrs: {
                type: "password",
                name: "new_password_confirmation",
                placeholder: _vm.$tt(
                  "settings.change_password.placeholder.new_password_confirmation"
                ),
                "data-vv-delay": "1000",
                "data-vv-as": _vm.$tt(
                  "settings.change_password.placeholder.new_password_confirmation"
                )
              },
              domProps: { value: _vm.auth.new_password_confirmation },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.auth,
                    "new_password_confirmation",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("new_password_confirmation")
              ? _c("span", { staticClass: "error-block" }, [
                  _vm._v(_vm._s(_vm.errors.first("new_password_confirmation")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$tt("common.cancel_button")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [
                _vm._v(
                  _vm._s(_vm.$tt("settings.change_password.submit_button"))
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }