<template>
  <a :class="classes" @click="ask()">

    <svg v-if="icon=='confirm'" class="svg-icon">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-confirm"></use>
    </svg>
    <svg v-else-if="icon=='cancel'" class="svg-icon">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-cancel"></use>
    </svg>
    <span>{{ text }}</span>

  </a>
</template>


<script>
module.exports = {
  props: [
    'icon',
    'text',
    'dialog',
    'api',
    'classes',
    'form_loading'
  ],
  data: () => ({
    loading: false
  }),
  methods: {
    ask: function(){
      if (this.loading) return false; //This only prevent re-trigger delete, but UI/UX still 'clickable'

      this.$confirm(this.dialog.description, this.dialog.title, {
        confirmButtonText: this.dialog.button.confirm,
        cancelButtonText: this.dialog.button.cancel,
        cancelButtonClass: 'btn',
        confirmButtonClass: 'btn btn-danger',
      }).then(() => {
        this.loading = true;
        this.$emit('update:form_loading', true);

        axios.post(this.api.url, {
          params: this.api.params
        })
        .then(response => {
          this.loading = false;
          this.$emit('update:form_loading', false);
          // console.log(response)

          if (response.data.status == true || response.data == true) {
            this.$parent.$message({
              type: 'success',
              message: this.dialog.message.success
            });

            if (typeof this.api.callback !== "undefined") {
              this.api.callback();
            }else {
              this.$parent.$router.push(this.api.redirect);
            }

          }else{

            if(response.data.error){

              this.$parent.$message({
                type: 'error',
                message: response.data.error
              });

            }else{

              this.$parent.$message({
                type: 'error',
                message: this.dialog.message.error
              });

            }

          }

        })


      }).catch(() => {
        // console.log('delete canceled')
      });

    }
  },
  watch: {
  },
  mounted() {
  }
}
</script>
