var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("header", { staticClass: "page-header-wrapper" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "page-header__main" }, [
          _c("h1", { staticClass: "page-header__title" }, [
            _vm._v(_vm._s(_vm.$tt("affiliates.title")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("nav", { staticClass: "page-nav-wrapper" }, [
      _c("ul", { staticClass: "page-nav" }, [
        _c("li", { staticClass: "page-nav__item" }, [
          _c(
            "a",
            { staticClass: "page-nav__link active", attrs: { href: "#" } },
            [_vm._v(_vm._s(_vm.$tt("affiliates.tab_1_title")))]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-nav__item" }, [
          _c(
            "a",
            {
              staticClass: "page-nav__link",
              attrs: {
                href:
                  "https://support.easystore.co/en/category/easystore-partner-program-17s9qbl/",
                target: "blank"
              }
            },
            [_vm._v(_vm._s(_vm.$tt("affiliates.tab_2_title")))]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-nav__item" }, [
          _c(
            "a",
            {
              staticClass: "page-nav__link",
              attrs: {
                href:
                  "https://support.easystore.co/en/article/easystore-partners-terms-conditions-1mnybnt/",
                target: "blank"
              }
            },
            [_vm._v(_vm._s(_vm.$tt("affiliates.tab_3_title")))]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-nav__item" }, [
          !_vm.affiliate_approved
            ? _c(
                "a",
                {
                  staticClass: "page-nav__link",
                  attrs: {
                    href:
                      "https://docs.google.com/forms/d/e/1FAIpQLSc3ZWnPPOnWTuQU-D3ycgBXTTAZx2IMobDMrJratWqaJnBWqw/viewform",
                    target: "blank"
                  }
                },
                [_vm._v(_vm._s(_vm.$tt("affiliates.tab_4_title")))]
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "layout" }, [
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card" }, [
            _c("header", { staticClass: "card__header" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$tt("affiliates.referral_title")))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card__section" }, [
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("div", { staticClass: "input-control-group" }, [
                  _c("input", {
                    ref: "copy",
                    staticClass: "input-control",
                    attrs: {
                      type: "text",
                      name: "affiliate_link",
                      readonly: ""
                    },
                    domProps: { value: _vm.referral_link }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-link", on: { click: _vm.copy } },
                    [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: { "xlink:href": "#icon-duplicate" }
                        })
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layout" }, [
        _c("section", { staticClass: "layout__section" }, [
          _vm.affiliate != null && _vm.affiliate.summary != undefined
            ? _c("div", { staticClass: "grid-summary" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card__section" }, [
                    _vm._v("\n                Total stores\n                "),
                    _c("div", { staticClass: "h2 text-center" }, [
                      _vm._v(_vm._s(_vm.affiliate.summary.total_stores))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card__section" }, [
                    _vm._v(
                      "\n                Total purchases\n                "
                    ),
                    _c("div", { staticClass: "h2 text-center" }, [
                      _vm._v(_vm._s(_vm.affiliate.summary.total_purchases))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card__section" }, [
                    _vm._v(
                      "\n                This month total stores\n                "
                    ),
                    _c("div", { staticClass: "h2 text-center" }, [
                      _vm._v(
                        _vm._s(_vm.affiliate.summary.this_month_total_stores)
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card__section" }, [
                    _vm._v(
                      "\n                This month total purchases\n                "
                    ),
                    _c("div", { staticClass: "h2 text-center" }, [
                      _vm._v(
                        _vm._s(_vm.affiliate.summary.this_month_total_purchases)
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card__section" }, [
                    _vm._v(
                      "\n                Lifetime earnings\n                "
                    ),
                    _c("div", { staticClass: "h2 text-center" }, [
                      _vm.lifetime_earnings.length
                        ? _c(
                            "ul",
                            _vm._l(_vm.lifetime_earnings, function(earning) {
                              return _c(
                                "div",
                                {
                                  key: earning.id,
                                  attrs: { value: earning.currency_code }
                                },
                                [
                                  _vm._v(
                                    _vm._s(earning.currency_code) +
                                      " " +
                                      _vm._s(earning.amount)
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", [_vm._v(" - ")])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card__section" }, [
                    _c("div", { staticClass: "tooltip" }, [
                      _vm._v("Unpaid earnings\n                  "),
                      _c("span", { staticClass: "tooltiptext" }, [
                        _vm._v(
                          "The commission can be withdrawn 30 days after the store subscribes"
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "h2 text-center" },
                      _vm._l(_vm.unpaid_earnings, function(
                        currency_code,
                        amount
                      ) {
                        return _c(
                          "div",
                          { key: currency_code, attrs: { value: amount } },
                          [_vm._v(_vm._s(amount) + " " + _vm._s(currency_code))]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c("date-range-picker", {
                attrs: { start_at: _vm.start_at, end_at: _vm.end_at },
                on: {
                  "update:start_at": function($event) {
                    _vm.start_at = $event
                  },
                  "update:end_at": function($event) {
                    _vm.end_at = $event
                  }
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "layout layout--1" }, [
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card__section" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$tt("affiliates.graph_2_title")))]),
                _vm._v(" "),
                _vm.chart_loading
                  ? _c("p", [_vm._v("Loading...")])
                  : _c("ve-line", {
                      attrs: {
                        data: _vm.chart.trial,
                        "legend-visible": false,
                        colors: ["#4184f0"],
                        settings: {
                          area: true,
                          lineStyle: { width: 3 },
                          areaStyle: { opacity: 0.5 }
                        },
                        extend: {
                          yAxis: {
                            interval: 1,
                            max: _vm.trial_max_value,
                            splitLine: {
                              lineStyle: {
                                opacity: 0.3
                              }
                            }
                          },

                          "xAxis.0.boundaryGap": false,
                          "xAxis.0.axisLabel": { rotate: 45 },

                          "series.0.smooth": false,
                          "series.0.symbol": "circle",
                          "series.0.symbolSize": [8, 8]
                        }
                      }
                    })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card__section" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$tt("affiliates.graph_3_title")))]),
                _vm._v(" "),
                _vm.chart_loading
                  ? _c("p", [_vm._v("Loading...")])
                  : _c("ve-line", {
                      attrs: {
                        data: _vm.chart.conversion,
                        "legend-visible": false,
                        colors: ["#4184f0"],
                        settings: {
                          area: true,
                          lineStyle: { width: 3 },
                          areaStyle: { opacity: 0.5 }
                        },
                        extend: {
                          yAxis: {
                            interval: 1,
                            max: _vm.conversion_max_value,
                            splitLine: {
                              lineStyle: {
                                opacity: 0.3
                              }
                            }
                          },

                          "xAxis.0.boundaryGap": false,
                          "xAxis.0.axisLabel": { rotate: 45 },

                          "series.0.smooth": false,
                          "series.0.symbol": "circle",
                          "series.0.symbolSize": [8, 8]
                        }
                      }
                    })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card__section" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$tt("affiliates.graph_4_title")))]),
                _vm._v(" "),
                _vm.chart_loading
                  ? _c("p", [_vm._v("Loading...")])
                  : _c("ve-line", {
                      attrs: {
                        data: _vm.chart.earning,
                        "legend-visible": false,
                        colors: ["#4184f0"],
                        settings: {
                          area: true,
                          lineStyle: { width: 3 },
                          areaStyle: { opacity: 0.5 }
                        },
                        extend: {
                          yAxis: {
                            splitLine: {
                              lineStyle: {
                                opacity: 0.3
                              }
                            }
                          },

                          "xAxis.0.boundaryGap": false,
                          "xAxis.0.axisLabel": { rotate: 45 },

                          "series.0.smooth": false,
                          "series.0.symbol": "circle",
                          "series.0.symbolSize": [8, 8]
                        }
                      }
                    })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }