window._ = require("lodash");

window.$ = window.jQuery = require("jquery");

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

window.Vue = require("vue");

// require('bootstrap-sass');

import VueRouter from "vue-router";
Vue.use(VueRouter);

import Vuex from "vuex";
Vue.use(Vuex);

import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

import VCharts from "v-charts";
Vue.use(VCharts);

import moment from "moment";
window._moment = moment;

require("froala-editor/js/froala_editor.pkgd.min");
require("./froala.js");

import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);

import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue";

Raven.config(process.env.MIX_SENTRY_DSN)
  .addPlugin(RavenVue, Vue)
  .install();
