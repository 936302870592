var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { staticClass: "site-auth", attrs: { blank: "" } }, [
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card__section" }, [
          _c("div", { staticClass: "site-auth__logo" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                attrs: {
                  src: "/images/partners/partners-logo.svg",
                  alt: "logo"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h1", { staticClass: "site-auth__title" }, [
            _vm._v(_vm._s(_vm.$tt("auth.reset_password.title")))
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              class: { "form-loading": _vm.form_loading },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.reset($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.auth.password,
                      expression: "auth.password",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:6",
                      expression: "'required|min:6'"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("password"),
                    "input-control": true
                  },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: _vm.$tt(
                      "auth.reset_password.placeholder.password"
                    ),
                    "data-vv-validate-on": "none",
                    "data-vv-as": _vm.$tt(
                      "auth.reset_password.placeholder.password"
                    )
                  },
                  domProps: { value: _vm.auth.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.auth, "password", $event.target.value.trim())
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("password")
                  ? _c("span", { staticClass: "error-block" }, [
                      _vm._v(_vm._s(_vm.errors.first("password")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.auth.password_confirmation,
                      expression: "auth.password_confirmation",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        min: 6,
                        confirmed: _vm.auth.password
                      },
                      expression:
                        "{ required: true, min: 6, confirmed: auth.password }"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("password_confirmation"),
                    "input-control": true
                  },
                  attrs: {
                    type: "password",
                    name: "password_confirmation",
                    placeholder: _vm.$tt(
                      "auth.reset_password.placeholder.password_confirmation"
                    ),
                    "data-vv-delay": "1000",
                    "data-vv-as": _vm.$tt(
                      "auth.reset_password.placeholder.password_confirmation"
                    )
                  },
                  domProps: { value: _vm.auth.password_confirmation },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.auth,
                        "password_confirmation",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("password_confirmation")
                  ? _c("span", { staticClass: "error-block" }, [
                      _vm._v(_vm._s(_vm.errors.first("password_confirmation")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(_vm.$tt("auth.reset_password.submit_button")))]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-wrapper input-wrapper--small" },
                [
                  _c("router-link", { attrs: { to: { name: "login" } } }, [
                    _vm._v(
                      _vm._s(_vm.$tt("auth.reset_password.back_to_login_link"))
                    )
                  ])
                ],
                1
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }