import { render, staticRenderFns } from "./apps.vue?vue&type=template&id=2cc8faf6&"
import script from "./apps.vue?vue&type=script&lang=js&"
export * from "./apps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/partner/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('2cc8faf6', component.options)
    } else {
      api.reload('2cc8faf6', component.options)
    }
    module.hot.accept("./apps.vue?vue&type=template&id=2cc8faf6&", function () {
      api.rerender('2cc8faf6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/partner/views/apps/apps.vue"
export default component.exports