var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-popover",
        {
          ref: "popover1",
          attrs: {
            placement: "bottom",
            "popper-class": "popover-actions",
            transition: "slide-fade"
          },
          model: {
            value: _vm.profile_dropdown,
            callback: function($$v) {
              _vm.profile_dropdown = $$v
            },
            expression: "profile_dropdown"
          }
        },
        [
          _c(
            "ul",
            {
              staticClass: "action-list",
              on: {
                click: function($event) {
                  _vm.profile_dropdown = false
                }
              }
            },
            [
              _c(
                "li",
                { staticClass: "icon-button" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "icon-button small",
                      attrs: { to: { name: "settings" } }
                    },
                    [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", { attrs: { "xlink:href": "#icon-setting" } })
                      ]),
                      _c("span", [_vm._v("Settings")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("li", {}, [
                _c(
                  "a",
                  {
                    staticClass: "icon-button small",
                    on: { click: _vm.logout }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-logout" } })
                    ]),
                    _c("span", [_vm._v("Logout")])
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            { name: "popover", rawName: "v-popover:popover1", arg: "popover1" }
          ],
          staticClass: "topbar-profile"
        },
        [
          _c("div", { staticClass: "topbar-profile__avatar" }, [
            _c("span", { staticClass: "avatar" }, [
              _c("img", {
                attrs: { src: _vm.$store.state.partner.avatar_url, alt: "" }
              })
            ]),
            _vm._v(" "),
            _vm.$store.state.partner.affiliate_approved
              ? _c("div", { staticClass: "check-icon" })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "topbar-profile__detail" }, [
            _vm.$store.state.partner.business_name != null
              ? _c("p", { staticClass: "store-name" }, [
                  _vm._v(_vm._s(_vm.$store.state.partner.business_name))
                ])
              : _c("p", { staticClass: "store-name" }, [
                  _vm._v(
                    _vm._s(_vm.$store.state.partner.first_name) +
                      " " +
                      _vm._s(_vm.$store.state.partner.last_name)
                  )
                ]),
            _vm._v(" "),
            _c("p", { staticClass: "user-name" }, [
              _vm._v(_vm._s(_vm.$store.state.partner.email))
            ])
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "svg-icon",
              class: { "rotate-180": _vm.profile_dropdown }
            },
            [_c("use", { attrs: { "xlink:href": "#icon-outline-arrowdown" } })]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }