import "./bootstrap";
import router from "./routes";
window.router = router;
import store from "./vuex";
import tracking from "./tracking";
Vue.prototype.$tracking = tracking;

Vue.component(
  "province-picker",
  require("./components/province-picker.vue").default
);
Vue.component(
  "country-picker",
  require("./components/country-picker.vue").default
);
Vue.component("account-menu", require("./components/account-menu.vue").default);
Vue.component(
  "publish-button",
  require("./components/publish-button.vue").default
);
Vue.component(
  "review-button",
  require("./components/review-button.vue").default
);
Vue.component(
  "delete-button",
  require("./components/delete-button.vue").default
);
Vue.component(
  "upload-button",
  require("./components/upload-button.vue").default
);
Vue.component("editor", require("./components/editor.vue").default);
Vue.component("layout", require("./components/layout.vue").default);
Vue.component("sidebar", require("./components/sidebar.vue").default);
Vue.component("topbar", require("./components/topbar.vue").default);

import Element from "element-ui";
Vue.use(Element);
import locale from "./locale";

new Vue({
  el: "#app",
  store,
  router,
  beforeCreate() {
    store.commit("boot");
    tracking.load();
  },
  created() {
    this.$validator.localize({
      en: {
        messages: {
          required: field => {
            return this.$tt("validation.required", {
              value1: field
            });
          },
          alpha: field => {
            return this.$tt("validation.alpha", { value1: field });
          },
          alpha_dash: field => {
            return this.$tt("validation.alpha_dash", {
              value1: field
            });
          },
          alpha_num: field => {
            return this.$tt("validation.alpha_num", {
              value1: field
            });
          },
          alpha_spaces: field => {
            return this.$tt("validation.alpha_spaces", {
              value1: field
            });
          },
          between: (field, args) => {
            return this.$tt("validation.between", {
              value1: field,
              value2: args[0],
              value3: args[1]
            });
          },
          confirmed: field => {
            return this.$tt("validation.confirmed", {
              value1: field
            });
          },
          decimal: (field, args) => {
            return this.$tt("validation.decimal", {
              value1: field,
              value2: args
            });
          },
          digits: (field, args) => {
            return this.$tt("validation.digits", {
              value1: field,
              value2: args
            });
          },
          email: field => {
            return this.$tt("validation.email", { value1: field });
          },
          max: (field, args) => {
            return this.$tt("validation.max", {
              value1: field,
              value2: args
            });
          },
          max_value: (field, args) => {
            return this.$tt("validation.max_value", {
              value1: field,
              value2: args
            });
          },
          min: (field, args) => {
            return this.$tt("validation.min", {
              value1: field,
              value2: args
            });
          },
          min_value: (field, args) => {
            return this.$tt("validation.min_value", {
              value1: field,
              value2: args
            });
          },
          numeric: field => {
            return this.$tt("validation.numeric", {
              value1: field
            });
          },
          url: field => {
            return this.$tt("validation.url", { value1: field });
          }
        }
      }
    });
  }
});

router.afterEach((to, from, next) => {
  document.body.classList.remove("overflow-hidden");

  tracking.pageview(to.fullPath);
});
