<template>
  <layout>
    <header class="page-header-wrapper">
      <div class="page-header">
        <div class="page-header__main">
          <h1 class="page-header__title">{{ $tt('affiliates.title') }}</h1>
        </div>
      </div>
    </header>

    <nav class="page-nav-wrapper">
      <ul class="page-nav">
        <li class="page-nav__item">
          <a href="#" class="page-nav__link active">{{ $tt('affiliates.tab_1_title') }}</a>
        </li>
        <li class="page-nav__item">
          <a
            href="https://support.easystore.co/en/category/easystore-partner-program-17s9qbl/"
            target="blank"
            class="page-nav__link"
          >{{ $tt('affiliates.tab_2_title') }}</a>
        </li>
        <li class="page-nav__item">
          <a
            href="https://support.easystore.co/en/article/easystore-partners-terms-conditions-1mnybnt/"
            target="blank"
            class="page-nav__link"
            >{{ $tt('affiliates.tab_3_title') }}</a>
        </li>
        <li class="page-nav__item">
          <a
            v-if="!affiliate_approved"
            href="https://docs.google.com/forms/d/e/1FAIpQLSc3ZWnPPOnWTuQU-D3ycgBXTTAZx2IMobDMrJratWqaJnBWqw/viewform"
            target="blank"
            class="page-nav__link"
            >{{ $tt('affiliates.tab_4_title') }}</a>
        </li>
      </ul>
    </nav>

    <div class="page-layout">
      <div class="layout">
        <section class="layout__section">
          <div class="card">
            <header class="card__header">
              <h3>{{ $tt('affiliates.referral_title') }}</h3>
            </header>
            <div class="card__section">
              <div class="input-wrapper input-wrapper--small">
                <div class="input-control-group">
                  <input
                    class="input-control"
                    type="text"
                    name="affiliate_link"
                    readonly
                    ref="copy"
                    :value="referral_link"
                  >
                  <!-- <button class="btn btn-link" @click="copy">{{ $tt('common.copy_button') }}</button> -->
                  <button class="btn btn-link" @click="copy">
                    <svg class="svg-icon">
                      <use xlink:href="#icon-duplicate"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <!-- <p><small>By signing up EasyStore Partner Program, you agree to our <a href="https://help.easystore.co//affiliate-program-resources/affiliate-terms-condition">EasyStore Affiliate Program Terms & Condition</a></small></p> -->
            </div>
          </div>
        </section>
      </div>
      <div class="layout">
        <section class="layout__section">
          <div class="grid-summary" v-if="affiliate != null && affiliate.summary != undefined">
            <div class="card">
              <div class="card__section">
                Total stores
                <div class="h2 text-center">{{affiliate.summary.total_stores}}</div>
              </div>
            </div>
            <div class="card">
              <div class="card__section">
                Total purchases
                <div class="h2 text-center">{{affiliate.summary.total_purchases}}</div>
              </div>
            </div>
            <div class="card">
              <div class="card__section">
                This month total stores
                <div class="h2 text-center">{{affiliate.summary.this_month_total_stores}}</div>
              </div>
            </div>
            <div class="card">
              <div class="card__section">
                This month total purchases
                <div class="h2 text-center">{{affiliate.summary.this_month_total_purchases}}</div>
              </div>
            </div>
            <div class="card">
              <div class="card__section">
                Lifetime earnings
                <div class="h2 text-center">
                  <ul v-if="lifetime_earnings.length">
                    <div v-bind:key="earning.id" v-for="earning in lifetime_earnings" :value="earning.currency_code">{{ earning.currency_code }} {{ earning.amount }}</div>
                  </ul>
                <div v-else> - </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card__section">
                <div class="tooltip">Unpaid earnings
                  <span class="tooltiptext">The commission can be withdrawn 30 days after the store subscribes</span>
                </div>
                <div class="h2 text-center">
                  <div v-for="(currency_code, amount) in unpaid_earnings" :value="amount" :key="currency_code">{{ amount }} {{ currency_code }}</div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="text-right">
            <date-range-picker :start_at.sync="start_at" :end_at.sync="end_at"></date-range-picker>
          </div>
        </section>
      </div>

      <div class="layout layout--1">
        <!-- 				<section class="layout__section">
					<div class="card">
						<div class="card__section">

							<h3>{{ $tt('affiliates.graph_1_title') }}</h3>

							<p v-if="chart_loading">Loading...</p>

							<ve-line
							v-else
							:data="chartData"
							:legend-visible="false"
							:colors="[ '#4184f0' ]"
							:settings="{ area: true, lineStyle: { width: 3 }, areaStyle: { opacity: 0.5 } }"
							:extend="{

								yAxis: {

									splitLine: {
										lineStyle: {
											opacity: 0.3
										}
									}
								},

								'xAxis.0.boundaryGap': false,
								'xAxis.0.axisLabel': { rotate: 45 },

								'series.0.smooth': false,
								'series.0.symbol': 'circle',
								'series.0.symbolSize': [8,8],

							}"
							></ve-line>

						</div>
					</div>
        </section>-->

        <section class="layout__section">
          <div class="card">
            <div class="card__section">
              <h3>{{ $tt('affiliates.graph_2_title') }}</h3>

              <p v-if="chart_loading">Loading...</p>

              <ve-line
                v-else
                :data="chart.trial"
                :legend-visible="false"
                :colors="[ '#4184f0' ]"
                :settings="{ area: true, lineStyle: { width: 3 }, areaStyle: { opacity: 0.5 } }"
                :extend="{

								yAxis: {
									interval: 1,
									max: trial_max_value,
									splitLine: {
										lineStyle: {
											opacity: 0.3
										}
									}
								},

								'xAxis.0.boundaryGap': false,
								'xAxis.0.axisLabel': { rotate: 45 },

								'series.0.smooth': false,
								'series.0.symbol': 'circle',
								'series.0.symbolSize': [8,8],

							}"
              ></ve-line>
            </div>
          </div>
        </section>

        <section class="layout__section">
          <div class="card">
            <div class="card__section">
              <h3>{{ $tt('affiliates.graph_3_title') }}</h3>

              <p v-if="chart_loading">Loading...</p>

              <ve-line
                v-else
                :data="chart.conversion"
                :legend-visible="false"
                :colors="[ '#4184f0' ]"
                :settings="{ area: true, lineStyle: { width: 3 }, areaStyle: { opacity: 0.5 } }"
                :extend="{

								yAxis: {
									interval: 1,
									max: conversion_max_value,
									splitLine: {
										lineStyle: {
											opacity: 0.3
										}
									}
								},

								'xAxis.0.boundaryGap': false,
								'xAxis.0.axisLabel': { rotate: 45 },

								'series.0.smooth': false,
								'series.0.symbol': 'circle',
								'series.0.symbolSize': [8,8],

							}"
              ></ve-line>
            </div>
          </div>
        </section>

        <section class="layout__section">
          <div class="card">
            <div class="card__section">
              <h3>{{ $tt('affiliates.graph_4_title') }}</h3>

              <p v-if="chart_loading">Loading...</p>

              <ve-line
                v-else
                :data="chart.earning"
                :legend-visible="false"
                :colors="[ '#4184f0' ]"
                :settings="{ area: true, lineStyle: { width: 3 }, areaStyle: { opacity: 0.5 } }"
                :extend="{

								yAxis: {

									splitLine: {
										lineStyle: {
											opacity: 0.3
										}
									}
								},

								'xAxis.0.boundaryGap': false,
								'xAxis.0.axisLabel': { rotate: 45 },

								'series.0.smooth': false,
								'series.0.symbol': 'circle',
								'series.0.symbolSize': [8,8],

							}"
              ></ve-line>
            </div>
          </div>
        </section>
      </div>
    </div>
  </layout>
</template>

<script>
export default {
  data() {
    return {
      affiliate: null,

      chart: {
        trial: {
          columns: ["Date", "Trial number"],
          rows: []
        },

        conversion: {
          columns: ["Date", "Conversion"],
          rows: []
        },

        earning: {
          columns: ["Date", "Earning"],
          rows: []
        }
      },
      chart_loading: false,

      start_at: "",
      end_at: "",
      referral_link: null,
      lifetime_earnings: [],
      unpaid_earnings: [],
      affiliate_approved: false
    };
  },

  components: {
    "date-range-picker": require("../../components/date-range-picker.vue")
      .default
  },

  watch: {
    range(range_new, range_old) {
      if (range_old) {
        this.retrieve();
      }
    }
  },
  computed: {
    range() {
      return this.start_at + this.end_at;
    },
    trial_max_value() {
      if (this.chart.trial.rows.length > 0) {
        var n = _.maxBy(this.chart.trial.rows, "Trial number");
        var k = Object.keys(n)[1];

        return n[k] > 0 ? n[k] + 2 : 5;
      }
    },
    conversion_max_value() {
      if (this.chart.conversion.rows.length > 0) {
        var n = _.maxBy(this.chart.conversion.rows, "Conversion");
        var k = Object.keys(n)[1];

        return n[k] > 0 ? n[k] + 2 : 5;
      }
    }
  },

  methods: {
    copy() {
      this.$refs.copy.select();
      document.execCommand("copy");
      this.$refs.copy.blur();
      this.$tracking.custom_event("copy", "affiliate");

      this.$message({
        message: this.$tt("affiliates.copy_success_message"),
        type: "success"
      });
    },

    retrieve() {
      if (!this.chart_loading) {
        this.chart_loading = true;

        axios
          .get("_api/affiliates", {
            params: { start_at: this.start_at, end_at: this.end_at }
          })
          .then(response => {
            this.affiliate = response.data.affiliate;

            this.lifetime_earnings = this.affiliate.summary.lifetime_earnings;

            this.unpaid_earnings = this.affiliate.summary.unpaid_earnings;

            this.affiliate_approved = this.affiliate.affiliate_approved;

            this.chart.trial.rows = this.affiliate.charts.trial;
            this.chart.conversion.rows = this.affiliate.charts.conversion;
            this.chart.earning.rows = this.affiliate.charts.earning;

            this.referral_link =
              process.env.MIX_WEBSITE_DOMAIN + "?ref=" + this.affiliate.code;
          })
          .catch(error => {
            this.error_message = error.response.data.error;
          })
          .then(() => {
            this.chart_loading = false;
          });
      }
    }
  },

  created() {}
};
</script>

<style lang="scss" scoped>
  .h2{
    font-size: 4rem;
    font-weight: bold;
    margin: 0.5em 0;
  }
  .grid-summary{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    .card{
      margin-top: 0;
    }
  }
  @media only screen and (min-width: 992px){
    .grid-summary{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    font-size: 10px;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    top: -5px;
    left: 125%;

    opacity: 0;
    transition: opacity .6s;
  }

  .tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>
