var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("header", { staticClass: "page-header-wrapper" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("div", { staticClass: "page-header__main" }, [
            _c("h1", { staticClass: "page-header__title" }, [
              _vm._v(_vm._s(_vm.$tt("stores.title")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page-header__action" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    _vm.show_create_store_dialog = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$tt("stores.create_store_button")))]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      !_vm.loading
        ? _c("section", [
            _c("nav", { staticClass: "page-nav-wrapper" }, [
              _c("ul", { staticClass: "page-nav" }, [
                _c("li", { staticClass: "page-nav__item" }, [
                  _c("a", { staticClass: "page-nav__link active" }, [
                    _vm._v(
                      _vm._s(_vm.$tt("stores.tab_1_title")) +
                        " (" +
                        _vm._s(_vm.stores == null ? "0" : _vm.stores.length) +
                        ")"
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.stores != null
              ? _c("div", { staticClass: "page-layout" }, [
                  _c("div", { staticClass: "layout" }, [
                    _c("section", { staticClass: "layout__section" }, [
                      _c("table", { staticClass: "table-list" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$tt("stores.list_name")))
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.$tt("stores.created_at")))
                            ]),
                            _vm._v(" "),
                            _c("th")
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.stores.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.stores, function(store) {
                                return _c("tr", { key: store.name }, [
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.storefront_url(store.name),
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(store.title))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(_vm.created_at(store.created_at))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-link",
                                          on: {
                                            click: function($event) {
                                              return _vm.login_store(store.id)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            { staticClass: "svg-icon" },
                                            [
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href": "#icon-store"
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tt("stores.login_button")
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("delete-button", {
                                        attrs: {
                                          text: _vm.$tt("stores.delete_store"),
                                          icon: "trash",
                                          form_loading: _vm.form_loading,
                                          api: {
                                            url: "/_api/stores/" + store.id,
                                            callback: function() {
                                              _vm.list()
                                            }
                                          },
                                          dialog: {
                                            title: _vm.$tt(
                                              "stores.delete_dialog_title",
                                              { value1: store.name }
                                            ),
                                            description: _vm.$tt(
                                              "stores.delete_dialog_description"
                                            ),
                                            button: {
                                              cancel: _vm.$tt(
                                                "common.cancel_button"
                                              ),
                                              confirm: _vm.$tt(
                                                "stores.delete_button"
                                              )
                                            },
                                            message: {
                                              success: _vm.$tt(
                                                "stores.delete_dialog_success_msg"
                                              ),
                                              error: _vm.$tt(
                                                "stores.delete_dialog_error_msg"
                                              )
                                            }
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$tt("stores.empty_row_message"))
                                  )
                                ])
                              ])
                            ])
                      ])
                    ])
                  ])
                ])
              : _c("div", { staticClass: "page-layout page-empty" }, [
                  _c("svg", { staticClass: "svg-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-store" } })
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "page-empty__header" }, [
                    _vm._v(_vm._s(_vm.$tt("stores.empty_store_message")))
                  ])
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("dialog-create-store", {
        attrs: { open_dialog: _vm.show_create_store_dialog },
        on: {
          "update:open_dialog": function($event) {
            _vm.show_create_store_dialog = $event
          },
          callback: _vm.list
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }