var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { staticClass: "site-auth", attrs: { blank: "" } }, [
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card__section" }, [
          _c("div", { staticClass: "site-auth__logo" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                attrs: {
                  src: "/images/partners/partners-logo.svg",
                  alt: "logo"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h1", { staticClass: "site-auth__title" }, [
            _vm._v(_vm._s(_vm.$tt("auth.forgot_password.title")))
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              class: { "form-loading": _vm.form_loading },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.send($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.email,
                      expression: "email",
                      modifiers: { trim: true }
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'"
                    }
                  ],
                  class: {
                    error: _vm.errors.has("email"),
                    "input-control": true
                  },
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: _vm.$tt(
                      "auth.forgot_password.placeholder.email"
                    ),
                    "data-vv-validate-on": "none",
                    "data-vv-as": _vm.$tt(
                      "auth.forgot_password.placeholder.email"
                    )
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value.trim()
                      },
                      function($event) {
                        _vm.email = $event.target.value.toLowerCase()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("email")
                  ? _c("span", { staticClass: "error-block" }, [
                      _vm._v(_vm._s(_vm.errors.first("email")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-wrapper input-wrapper--small" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$tt("auth.forgot_password.submit_button"))
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-wrapper input-wrapper--small" },
                [
                  _c("router-link", { attrs: { to: { name: "login" } } }, [
                    _vm._v(
                      _vm._s(_vm.$tt("auth.forgot_password.back_to_login_link"))
                    )
                  ])
                ],
                1
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }