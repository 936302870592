// require('font-awesome/css/font-awesome.css')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('font-awesome/css/font-awesome.css')
require('froala-editor/css/froala_style.min.css')

// // https://www.froala.com/wysiwyg-editor/languages
require("froala-editor/js/languages/id.js"); //Indonesian
// require('froala-editor/js/languages/ja.js') //Japanese
// require('froala-editor/js/languages/ko.js') //Korean
// require('froala-editor/js/languages/th.js') //Thai
require("froala-editor/js/languages/zh_tw.js");
require("froala-editor/js/languages/zh_cn.js");
// Bahasa Malaysia, Filipino not available
