var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "page-frame__header" }, [
    _c("nav", { staticClass: "nav-topbar" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "nav-topbar__list" }, [
        _c("div", { staticClass: "nav-topbar__item" }, [_c("account-menu")], 1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-topbar__logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: { src: "/images/partners/partners-logo.svg", alt: "logo" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }