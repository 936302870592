<template>

	<header class="page-frame__header">
			<nav class="nav-topbar">

				<div class="nav-topbar__logo">
					<a href="/"><img src="/images/partners/partners-logo.svg" alt="logo"></a>
				</div>

				<div class="nav-topbar__list">
					<div class="nav-topbar__item">
						<account-menu></account-menu>
						
						<!-- <div class="topbar-profile">
							<div class="topbar-profile__avatar">
								<span class="avatar">
									<img :src="$store.state.partner.avatar_url" alt="">
								</span>
							</div>
							<div class="topbar-profile__detail">
								<p v-if="$store.state.partner.business_name != null" class="store-name">{{ $store.state.partner.first_name }} {{ $store.state.partner.last_name }}</p>
								<p v-else class="store-name">{{ $store.state.partner.first_name }} {{ $store.state.partner.last_name }}</p>
								<p class="user-name">{{ $store.state.partner.email }}</p>
							</div>
						</div> -->
						
					</div>
				</div>
			</nav>
		</header>

</template>

<script>
export default {};
</script>
