import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    partner: {
      business_name: null,
      first_name: null,
      last_name: null,
      email: null,
      avatar_url: null,
      referral_code: null,
      created_at: null,
      apps_count: null,
      themes_count: null,
      dev_store_count: null,
      total_referral_store_count: null,
      paid_referral_store_count: null
    },
    public: {
      country_code: null,
      locale: null
    }
  },
  mutations: {
    boot(state) {
      axios
        .get("/_api/global")
        .then(response => {
          Object.assign(state.partner, response.data.partner);
          window.setLocale(response.data.partner.locale);
          window.Intercom("boot", {
            app_id: process.env.MIX_INTERCOM_APP_ID,
            user_hash: response.data.partner.user_hash,
            first_name: response.data.partner.first_name,
            last_name: response.data.partner.last_name,
            email: response.data.partner.email,
            referral_code: response.data.partner.referral_code,
            partner_created_at: response.data.partner.created_at,
            apps_count: response.data.partner.apps_count,
            themes_count: response.data.partner.themes_count,
            dev_store_count: response.data.partner.dev_store_count,
            total_referral_store_count: response.data.partner.total_referral_store_count,
            paid_referral_store_count: response.data.partner.paid_referral_store_count
          });
        })
        .catch(error => {});
    },

    update(state) {
      axios
        .get("/_api/global")
        .then(response => {
          Object.assign(state.partner, response.data.partner);
          window.setLocale(response.data.partner.locale);
          window.Intercom("update", {
            app_id: process.env.MIX_INTERCOM_APP_ID,
            user_hash: response.data.partner.user_hash,
            first_name: response.data.partner.first_name,
            last_name: response.data.partner.last_name,
            email: response.data.partner.email,
            referral_code: response.data.partner.referral_code,
            partner_created_at: response.data.partner.created_at,
            apps_count: response.data.partner.apps_count,
            themes_count: response.data.partner.themes_count,
            dev_store_count: response.data.partner.dev_store_count,
            total_referral_store_count: response.data.partner.total_referral_store_count,
            paid_referral_store_count: response.data.partner.paid_referral_store_count
          });
        })
        .catch(error => {});
    },

    public(state) {
      axios
        .get("_api/public")
        .then(response => {
          Object.assign(state.public, response.data);

          window.setLocale(state.public.locale);
        })
        .catch(error => {})
        .then(() => {});
    }
  }
});
