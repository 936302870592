<template>

	<layout blank class="site-auth">
		
		<div class="page-layout">

			<div class="card">

				<div class="card__section">
					<div class="site-auth__logo">
						<a href="/"><img src="/images/partners/partners-logo.svg" alt="logo"></a>
					</div>
					<hr>
					

					<h1 class="site-auth__title">{{ $tt('auth.signup.title') }}</h1>

					<form :class="{ 'form-loading': form_loading }" @submit.prevent="signup">
						<div class="form-group form-group--2">
							<div class="input-wrapper input-wrapper--small">

								<input 
								type="text" 
								name="first_name" 
								:placeholder="$tt('auth.signup.placeholder.first_name')"
								:class="{ error:errors.has('first_name'), 'input-control': true }"
								v-model.trim="auth.first_name"
								@input="auth.first_name = toCapitalize( auth.first_name )"
								v-validate="'required'"
								data-vv-validate-on="none"
								:data-vv-as="$tt('auth.signup.placeholder.first_name')"
								>

								<span v-if="errors.has('first_name')" class="error-block">{{ errors.first('first_name') }}</span>

							</div>

							<div class="input-wrapper input-wrapper--small">

								<input 
								type="text" 
								name="last_name" 
								:placeholder="$tt('auth.signup.placeholder.last_name')"
								:class="{ error:errors.has('last_name'), 'input-control': true }"
								v-model.trim="auth.last_name"
								@input="auth.last_name = toCapitalize( auth.last_name )"
								v-validate="'required'"
								data-vv-validate-on="none"
								:data-vv-as="$tt('auth.signup.placeholder.last_name')"
								>

								<span v-if="errors.has('last_name')" class="error-block">{{ errors.first('last_name') }}</span>
							</div>
						</div>

						<div class="input-wrapper input-wrapper--small">

							<input 
							type="text" 
							name="email" 
							:placeholder="$tt('auth.signup.placeholder.email')"
							:class="{ error:errors.has('email'), 'input-control': true }"
							v-model.trim="auth.email"
							@input="auth.email = $event.target.value.toLowerCase()"
							v-validate="'required|email'"
							data-vv-validate-on="none"
							:data-vv-as="$tt('auth.signup.placeholder.email')"
							>

							<span v-if="errors.has('email')" class="error-block">{{ errors.first('email') }}</span>
						</div>

						<div class="input-wrapper input-wrapper--small">

							<input 
							type="password" 
							name="password" 
							:placeholder="$tt('auth.signup.placeholder.password')"
							:class="{ error:errors.has('password'), 'input-control': true }"
							v-model.trim="auth.password"
							v-validate="'required|min:6'"
							data-vv-validate-on="none"
							:data-vv-as="$tt('auth.signup.placeholder.password')"
							>

							<span v-if="errors.has('password')" class="error-block">{{ errors.first('password') }}</span>
						</div>

						<div class="input-wrapper">

							<country-picker
							v-model.trim="auth.country_code"
							classes="input-large"
							></country-picker>				 

						</div>

						<div class="input-wrapper input-wrapper--small">
							<button type="submit" class="btn btn-primary">{{ $tt('auth.signup.submit_button') }}</button>
						</div>
						
						<div class="input-wrapper input-wrapper--small">
							{{ $tt('auth.signup.back_to_login_part_1') }} <router-link :to="{ name: 'login' }">{{ $tt('auth.signup.back_to_login_link') }}</router-link>
						</div>
					</form>


				</div>

			</div>

		</div>

	</layout>

</template>

<script>
export default {
  data() {
    return {
      auth: {
        first_name: null,
        last_name: null,
        email: this.$route.query.email,
        password: null,
        country_code: null
      },

      form_loading: false
    };
  },

  watch: {
    "$store.state.public.country_code": {
      handler(o) {
        this.auth.country_code = o;
      },
      immediate: true
    }
  },

  methods: {
    toast(type, message) {
      this.$message({
        message: message,
        type: type
      });
    },
    signup() {
      this.$validator.validateAll().then(result => {
        if (result && !this.form_loading) {
          this.form_loading = true;

          axios
            .post("_api/auth/signup", this.auth)
            .then(response => {
              if (response.data.success) {
                this.$tracking.signup("direct");

                this.$store.commit("boot");

                this.$router.push("/");
              }
            })
            .catch(error => {
              this.toast("error", error.response.data.error);
            })
            .finally(() => {
              this.form_loading = false;
            });
        }
      });
    },
    toCapitalize(v) {
      return v
        .split(" ")
        .map(x => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");
    }
  }
};
</script>
