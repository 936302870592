export default {
	load() {
		window.dataLayer = window.dataLayer || [];
		window.gtag = function gtag() {
			dataLayer.push(arguments);
		};
		gtag("js", new Date());

		gtag("config", process.env.MIX_GA_TRACKING_ID);
	},

	pageview(path) {
		gtag("config", process.env.MIX_GA_TRACKING_ID, {
			page_path: path
		});
	},

	login(method) {
		gtag("event", "login", { method: method });
	},

	signup(method) {
		gtag("event", "sign_up", { method: method });
	},

	custom_event(event, label) {
		gtag("event", event, {
			event_label: label
		});
	}
};
