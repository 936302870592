var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { staticClass: "site-home" }, [
    _c("header", { staticClass: "page-header-wrapper" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "page-header__main" }, [
          _vm.$store.state.partner.first_name
            ? _c("h1", { staticClass: "page-header__title" }, [
                _vm._v(
                  "Hi, " +
                    _vm._s(_vm.$store.state.partner.first_name) +
                    " 👋 Welcome to EasyStore Partner Portal"
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page-layout home-layout" }, [
      _c("div", { staticClass: "layout layout--1" }, [
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card home-card" }, [
            _c(
              "div",
              { staticClass: "card__section" },
              [
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "home-icon" }, [
                  _c("img", {
                    attrs: { src: "/images/partners/affiliates.svg", alt: "" }
                  })
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "home-title" }, [
                  _vm._v(_vm._s(_vm.$tt("home.card_1_title")))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$tt("home.card_1_description")))]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { to: { name: "affiliates" } }
                  },
                  [_vm._v(_vm._s(_vm.$tt("home.card_1_cta")))]
                ),
                _vm._v(" "),
                _c("br"),
                _c("br")
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "layout__section" }, [
          _c("div", { staticClass: "card home-card" }, [
            _c(
              "div",
              { staticClass: "card__section" },
              [
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "home-icon" }, [
                  _c("img", {
                    attrs: { src: "/images/partners/app.svg", alt: "" }
                  })
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "home-title" }, [
                  _vm._v(_vm._s(_vm.$tt("home.card_3_title")))
                ]),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$tt("home.card_3_description"))
                  }
                }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { to: { name: "apps" } }
                  },
                  [_vm._v(_vm._s(_vm.$tt("home.card_3_cta")))]
                ),
                _vm._v(" "),
                _c("br"),
                _c("br")
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _c("br")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }