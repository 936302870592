<template>
	
	<el-upload
	ref="upload"
	:action="apiUrl"
	:multiple="false"
	:accept="accept"
	:show-file-list="false"
	:before-upload="beforeUpload"
	:on-error="onError"
	:on-success="onSuccess"
	:disabled="button_disabled"
	>
	<a :class="{ 'btn': true, 'btn-loading': button_disabled }">{{ $tt('apps.icon.upload_button') }}</a>
	</el-upload>


</template>

<script>
	
	export default {

		props: [ 'apiUrl', 'accept', 'mbLimit', 'form_loading', 'weight', 'height', 'image_url' ],

		data() {
			return {

				button_disabled: false

			};
		},

		watch:{

			button_disabled(){

				this.$emit('update:form_loading', this.button_disabled);

			}

		},

		methods:{

			beforeUpload(file){

				this.button_disabled = true;

				if(this.accept.indexOf(file.type) === -1){

					this.$message.error( 'only support '+this.accept );

					this.button_disabled = false;

					return false;

				}

				if(file.size / 1024 / 1024 > this.mbLimit){

					this.$message.error( this.$tt('upload.exceed_limit', { value1: this.mbLimit }) );

					this.button_disabled = false;

					return false;

				}


				return true;

			},

			onSuccess(response, file){

				if(response.url){

					this.$emit('update:image_url', response.url);

				}

				this.button_disabled = false;

			},

			onError(error, file){

				this.$message.error( 'upload error.' );

				this.button_disabled = false;


			}

		}


	}


</script>